import React from "react";
import XenaAIData from "../../../../data/dashboard/xena_ai.json";
import { XenaAIResults } from "./XenaAIResults";
import { Header } from "./Header";

export const XenaAISection = () => {
  return (
    <div
      id="xenaAI"
      className="flex flex-col bg-white row-span-2 col-span-12 sm:col-span-5 lg:col-span-4 xl:col-span-3  order-7 overflow-hidden border-2 border-[#FF57A6] rounded-xl"
    >
      {/* <XenaChipContainer
        filters={filters}
        setFilters={setFilters}
        filteredData={filteredData}
      /> */}
      <Header filteredData={XenaAIData} />
      <XenaAIResults filteredData={XenaAIData} />
    </div>
  );
};
