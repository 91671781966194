import React, { useRef } from 'react'
import { motion } from "framer-motion";
import { CardHeader } from '../Common/CardHeader';
import { LineChartExpanded } from './LineChartExpanded';
import useOutSideClick from '../../../../hooks/useOutsideClick';
import DateSelector from '../../DateSelector';
import useDashData from '../../../../hooks/useDashData';

export const ExpandedCard = ({ setIsExpanded }) => {
  let wrapperRef = useRef(null);
  useOutSideClick(wrapperRef, ()=>{ setIsExpanded(false) })
  let {
    dashData,
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
  } = useDashData();
  return (
    <motion.div 
      initial={{ backdropFilter: "blur(0px)", opacity: 0.7 }}
      animate={{ backdropFilter: "blur(10px)", opacity: 1 }}
      exit={{ backdropFilter: "blur(0px)", opacity: 0 }}
      transition={{
        // staggerChildren: .1,
        type: "spring",
        bounce: 0
      }} 
      className='flex justify-center items-center fixed p-20 top-0 left-0 z-[99] w-screen h-screen bg-[#00000062]'
    >
        <motion.div 
          ref={wrapperRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
          layoutId='sales-card'
          layout
          className="relative w-full min-w-[320px] max-w-[800px] flex flex-col justify-between transform shadow-[#DEF5ED] transition-all order-1 col-span-6 sm:col-span-3 row-span-2 bg-[#DEF5ED] rounded-2xl 2xl:rounded-[40px] overflow-hidden"
        >
          <div className="p-5 pb-0 cursor-pointer ">
            <CardHeader
              headerName="TOTAL SALES"
              trendPercentage={dashData.data.salesChange}
              isTrendUp={dashData.data.salesTrendUp}
            />
            <p className="text-3xl 2xl:text-6xl my-2 2xl:my-4 font-Raleway">${new Intl.NumberFormat('en-US').format(dashData.data.sales)}</p>
          </div>
          <LineChartExpanded dashData={dashData} />
          <div className='absolute top-2 right-3'>
            <DateSelector 
              selected={selectedDateRange}
              setSelected={setSelectedDateRange}
              datesRange={datesRange}
            />
          </div>
        </motion.div>
    </motion.div >
  )
}
