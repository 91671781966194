import React from "react";
import {
  ChipIcon,
  TableIcon,
  CollectionIcon,
  LightningBoltIcon,
  ClipboardListIcon,
  ChartSquareBarIcon,
  DocumentReportIcon,
  // CloudIcon,
  ShoppingCartIcon,
} from "@heroicons/react/solid";

import {
  // CloudIcon as CloudIconOutline,
  CursorClickIcon as CursorClickIconOutline,
  ChipIcon as ChipIconOutline,
  TableIcon as TableIconOutline,
  CollectionIcon as CollectionIconOutline,
  ShoppingCartIcon as ShoppingCartIconOutline,
  ClipboardListIcon as ClipboardListIconOutline,
  LightningBoltIcon as LightningBoltIconOutline,
  ChartSquareBarIcon as ChartSquareBarIconOutline,
  DocumentReportIcon as DocumentReportIconOutline,
  CursorClickIcon,
} from "@heroicons/react/outline";

import { DesktopNavigation } from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";

function Sidebar() {
  let IconStyle =
    "w-8 h-8 2xl:w-12 2xl:h-12 p-1.5 2xl:p-2 hover:bg-white hover:text-black rounded-xl";
  let links = [
    {
      icon: <ChipIcon className={IconStyle} />,
      iconOutline: <ChipIconOutline className={IconStyle} />,
      to: "/",
      name: "Xena AI",
    },
    {
      icon: <CollectionIcon className={IconStyle} />,
      iconOutline: <CollectionIconOutline className={IconStyle} />,
      to: "/campaigns",
      name: "Campaigns",
    },

    // {
    //   icon: <CollectionIcon className={IconStyle} />,
    //   iconOutline: <CollectionIconOutline className={IconStyle} />,
    //   to: "/",
    //   name: "Campaigns",
    // },
    // {
    //   icon: <CloudIcon className={IconStyle} />,
    //   iconOutline: <CloudIconOutline className={IconStyle} />,
    //   to: "/xenacloud",
    //   name: "XENA Cloud",
    // },
    {
      icon: <ShoppingCartIcon className={IconStyle} />,
      iconOutline: <ShoppingCartIconOutline className={IconStyle} />,
      to: "/products",
      name: "Products",
    },
    {
      icon: <TableIcon className={IconStyle} />,
      iconOutline: <TableIconOutline className={IconStyle} />,
      to: "/inventory-management",
      name: "Inventory Management",
      isDisabled: false,
      isComingSoon: false,
    },
    {
      icon: <CursorClickIcon className={IconStyle} />,
      iconOutline: <CursorClickIconOutline className={IconStyle} />,
      to: "/keyword-report",
      name: "Keyword Report",
      isDisabled: false,
      isComingSoon: false,
    },
    {
      icon: <ChartSquareBarIcon className={IconStyle} />,
      iconOutline: <ChartSquareBarIconOutline className={IconStyle} />,
      to: "/sales-forcast",
      name: "Sales Forcasting",
      isDisabled: true,
      isComingSoon: true,
    },
    {
      icon: <LightningBoltIcon className={IconStyle} />,
      iconOutline: <LightningBoltIconOutline className={IconStyle} />,
      to: "/competetor-Intelligence",
      name: "Competetor Intelligence",
      isDisabled: true,
      isComingSoon: true,
    },
    {
      icon: <ClipboardListIcon className={IconStyle} />,
      iconOutline: <ClipboardListIconOutline className={IconStyle} />,
      to: "/keywords-seo",
      name: "Keywords & SEO",
      isDisabled: true,
      isComingSoon: true,
    },
    {
      icon: <DocumentReportIcon className={IconStyle} />,
      iconOutline: <DocumentReportIconOutline className={IconStyle} />,
      to: "/reports",
      name: "Download Reports",
      isDisabled: true,
      isComingSoon: true,
    },
  ];
  return (
    <>
      <DesktopNavigation links={links} />
      <MobileNavigation links={links} />
    </>
  );
}

export default Sidebar;
