import { LayoutGroup, motion } from "framer-motion";

function TopSellingSKUs({ dashData }) {
  console.log(dashData);
  const cardAnimation = {
    animation: {
      opacity: 1,
      y: 0,
      transition: {
        // staggerChildren: .1,
        type: "spring",
        bounce: 0,
      },
    },
    hidden: { opacity: 0, y: 200 },
  };

  return (
    <LayoutGroup>
      <motion.div
        variants={cardAnimation}
        animate="animation"
        initial="hidden"
        layoutId="top-selling-skus-card"
        className="order-10 col-span-12  sm:col-span-6  md:col-span-5 lg:col-span-4 xl:col-span-3 row-span-1 flex flex-col gap-2 h-full"
      >
        <p className="text-[12px] 2xl:text-base font-semibold">
          Top Selling SKUs
        </p>
        <div className="flex flex-col overflow-y-auto sm:max-h-[30vh] xl:max-h-[35vh] 2xl:max-h-max pr-1">
          <div className="flex flex-col gap-2 w-full">
            {dashData?.data?.topSellingSKUs?.map((item, id) => {
              const textLength = item?.title?.length;
              return (
                <div
                  key={id}
                  className="flex items-center w-full gap-2 bg-white border-2 text-sm p-2 rounded-lg"
                >
                  <img
                    className="w-1/4 sm:w-1/3 md:w-1/4 border-2 rounded-xl"
                    src={item?.image}
                    alt=""
                  />
                  <div className="flex flex-col gap-1">
                    <p className="flex gap-1 text-left text-pink-600 font-medium text-xs">
                      {item?.sku}{" "}
                      {/* <ArrowTopRightOnSquareIcon className="w-4 h-4" /> */}
                    </p>
                    <p className="text-left text-ellipsis flex overflow-hidden text-xs text-gray-500">
                      {textLength > 32
                        ? `${item?.title?.substring(
                            0,
                            30
                          )}...${item?.title?.substring(
                            textLength - 30,
                            textLength
                          )}`
                        : item?.title}
                    </p>
                    <p className="text-left font-Raleway font-semibold">
                      $
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(item?.totalSales)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
    </LayoutGroup>
  );
}

export default TopSellingSKUs;
