import TrendPillBox from "./TrendPillBox";

export function CardHeader({ headerName, trendPercentage, isTrendUp }) {
    return (
        <div className="flex items-center">
            <p className="mr-2 text-[8px] xl:text-sm 2xl:text-xl font-bold uppercase">{headerName}</p>
            { trendPercentage && <TrendPillBox trendPercentage={trendPercentage} isTrendUp={isTrendUp} />}
        </div>
    );
}
