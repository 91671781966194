import { useEffect, useState } from "react";
import ForceGraph2d from "react-force-graph-2d";
const initialData = {
  nodes: [
    {
      id: "id1",
      name: "Dummy data 1",
      radius: 5,
    },
    {
      id: "id2",
      name: "Dummy Data 2 ",
      radius: 5,
    },
  ],
  links: [
    {
      source: "id1",
      target: "id2",
    },
  ],
};

const campaignData = {
  nodes: [
    {
      id: "id1",
      name: "Nestle",
      radius: 15,
      type: "brand",
    },
    {
      id: "id2",
      name: "1- Keto Creamer",
      radius: 5,
      type: "product",
    },
    {
      id: "id3",
      name: "1A-Originial",
      radius: 5,
      type: "subproduct",
    },
    {
      id: "id4",
      name: `1A1- AUTO`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id5",
      name: `1A2 - Kwd`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id6",
      name: `1A3- ASIN`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id7",
      name: `1A4- Categry`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id8",
      name: `1A5- SB video campaign`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id9",
      name: `1A6- Sponsored Display`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id10",
      name: `1B-Turmeric`,
      radius: 5,
      type: "subproduct",
    },
    {
      id: "id11",
      name: `2- ice cream`,
      radius: 5,
      type: "product",
    },
    {
      id: "id12",
      name: `2A-originial`,
      radius: 5,
      type: "subproduct",
    },
    {
      id: "id13",
      name: `2B-originial`,
      radius: 5,
      type: "subproduct",
    },
    {
      id: "id14",
      name: `1A2-1-SP KW EX "keto coffee creamer"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id15",
      name: `1A2-2-SP KW EX "keto"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id16",
      name: `1A3 1 SP ASIN "B08TDP1TQC"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id17",
      name: `1A3 2 SP ASIN "B0793JSWC6"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id18",
      name: `1A3 2 SP ASIN "B095DPQR42"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id19",
      name: `1A4 1 SP CT "ghee"`,
      radius: 5,
      roas: 0.55,
      type: "campaign",
    },
    {
      id: "id20",
      name: `1A4 2 SP CT "Plant-Based Coffee Creamers"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id21",
      name: `1A5 1 SBV KW EX "keto coffee creamer"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id22",
      name: `1B1- AUTO`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id23",
      name: `1B2 - Kwd`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id24",
      name: `1B3- ASIN`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id25",
      name: `1B4- Categry`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id26",
      name: `1B5- SB video campaign`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id27",
      name: `1B6- Sponsored Display`,
      radius: 5,
      type: "campaigntype",
    },
    {
      id: "id28",
      name: `1B2 - 1 - keto coffe creamery`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id29",
      name: `1B2 - 2 - Keto`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id30",
      name: `1B3 1 SP ASIN "B08TDP1TQC"`,
      radius: 5,
      roas: 0.4,
      type: "campaign",
    },
    {
      id: "id31",
      name: `1B3 2 SP ASIN "B0793JSWC6"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id32",
      name: `1B3 3 SP ASIN "B095DPQR42"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id33",
      name: `1B4 1 SP CT "ghee"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id34",
      name: `1B4 2 SP CT "Plant-Based Coffee Creamers"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id35",
      name: `1B5 1 SBV KW EX "keto coffee creamer"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id36",
      name: `1A1- SP AUTO "Original"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
    {
      id: "id37",
      name: `1B1- SP AUTO "Turmeric"`,
      radius: 5,
      type: "campaign",
      roas: 2,
    },
  ],
  links: [
    {
      source: "id1",
      target: "id11",
    },
    {
      source: "id22",
      target: "id37",
    },
    {
      source: "id4",
      target: "id36",
    },
    {
      source: "id1",
      target: "id11",
    },
    {
      source: "id26",
      target: "id35",
    },
    {
      source: "id25",
      target: "id33",
    },
    {
      source: "id25",
      target: "id34",
    },
    {
      source: "id24",
      target: "id30",
    },
    {
      source: "id24",
      target: "id31",
    },
    {
      source: "id24",
      target: "id32",
    },
    {
      source: "id23",
      target: "id29",
    },
    {
      source: "id23",
      target: "id28",
    },
    {
      source: "id7",
      target: "id19",
    },
    {
      source: "id10",
      target: "id22",
    },
    {
      source: "id10",
      target: "id23",
    },
    {
      source: "id10",
      target: "id24",
    },
    {
      source: "id10",
      target: "id25",
    },
    {
      source: "id10",
      target: "id26",
    },
    {
      source: "id10",
      target: "id27",
    },
    {
      source: "id8",
      target: "id21",
    },
    {
      source: "id7",
      target: "id20",
    },
    {
      source: "id6",
      target: "id16",
    },
    {
      source: "id6",
      target: "id18",
    },
    {
      source: "id6",
      target: "id17",
    },
    {
      source: "id5",
      target: "id14",
    },
    {
      source: "id5",
      target: "id15",
    },
    {
      source: "id11",
      target: "id12",
    },
    {
      source: "id11",
      target: "id13",
    },
    {
      source: "id1",
      target: "id2",
    },
    {
      source: "id2",
      target: "id3",
    },
    {
      source: "id3",
      target: "id4",
    },
    {
      source: "id3",
      target: "id5",
    },
    {
      source: "id3",
      target: "id6",
    },
    {
      source: "id3",
      target: "id7",
    },
    {
      source: "id3",
      target: "id8",
    },
    {
      source: "id3",
      target: "id9",
    },
    {
      source: "id2",
      target: "id10",
    },
  ],
};

function wrapText(context, text, x, y, maxWidth, lineHeight) {
  var words = text.split(" ");
  var line = "";

  for (var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + " ";
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = words[n] + " ";
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}

function ForceGraph() {
  const [data, setData] = useState(initialData);
  useEffect(() => {
    setData(campaignData);
  }, []);
  return (
    <ForceGraph2d
      graphData={data}
      width={window.innerWidth / 1.31}
      height={window.innerHeight * 0.8}
      nodeColor={(node) => {
        if (node.type === "campaign") {
          return node.roas < 1 ? "#DE1A1A" : "#5E8C61";
        } else {
          return "#373E40";
        }
      }}
      nodeCanvasObjectMode={() => "after"}
      linkWidth={5}
      linkDirectionalParticles={1}
      linkDirectionalParticleSpeed={0.011}
      d3VelocityDecay={0.2}
      enableNodeDrag={true}
      nodeVal="radius"
      nodeCanvasObject={(node, ctx, globalScale) => {
        let label = node.name;
        node.roas && (label += ` ROAS=${node.roas}`);
        const fontSize = 2;
        ctx.font = `bold ${fontSize}px Sans-Serif`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "white";
        wrapText(ctx, label, node.x, node.y - 5, 10, 2);
        // const lineHeight = fontSize * 1.2;
        // const lines = label.split(" ");
        // let x = node.x;
        // let y = node.y - lineHeight;
        // for (let i = 0; i < lines.length; ++i) {
        //   ctx.fillText(lines[i], x, y);
        //   y += lineHeight;
        //   // ctx.fillText(lines[i], x, y);
        // }
        // ctx.fillText(label, node.x, node.y);
      }}
      // dagMode={"td"}
      // dagLevelDistance={60}
    />
  );
}

export default ForceGraph;
