import { useEffect } from "react";
import { useState } from "react";
import useProductData from "../../hooks/useProductsData";
import SidebarDashGridLayout from "../../layouts/SidebarDashGridLayout";
import { Pagination } from "./Pagination";
import { FilterSection } from "./FilterSection";
import styled from "styled-components";
import { TableColumn } from "./TableColumn";

const generateValue = (product, header) => {
  const prefix = header?.prefix || "";
  const suffix = header?.suffix || "";
  if (
    product[header.columnName] === null ||
    product[header.columnName] === undefined
  ) {
    return "--";
  } else {
    if (header.isCurrency) {
      return (
        prefix +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(product[header.columnName]) +
        suffix
      );
    } else if (header.isNumber) {
      return (
        prefix +
        new Intl.NumberFormat("en-US").format(product[header.columnName]) +
        suffix
      );
    } else {
      return prefix + product[header.columnName] + suffix;
    }
  }
};

const TableHeaders = [
  {
    name: "Product",
    columnName: "title",
    isResizable: true,
    initialWidth: 350,
    // isFixed: true,
    imageurl: "imgurl",
    orderDisabled: true,
  },
  {
    name: "Parent ASIN",
    columnName: "parentasin",
    isResizable: true,
    orderDisabled: false,
  },
  {
    name: "Child ASIN",
    columnName: "childasin",
    isResizable: true,
    orderDisabled: false,
  },
  { name: "SKU", columnName: "sku", isResizable: true, orderDisabled: false },
  { name: "ACOS", columnName: "acos", suffix: "%" },
  {
    name: "Spend",
    columnName: "spend",
    isCurrency: true,
    orderDisabled: false,
  },
  {
    name: "Ad Sales",
    columnName: "adsales",
    isCurrency: true,
    orderDisabled: false,
  },
  {
    name: "Total Sales",
    columnName: "totalSales",
    isCurrency: true,
    orderDisabled: false,
  },
  {
    name: "Clicks",
    columnName: "clicks",
    isNumber: true,
    orderDisabled: false,
  },
  { name: "CTR", columnName: "ctr", suffix: "%", orderDisabled: false },
  { name: "CPC", columnName: "cpc", prefix: "$", orderDisabled: false },
  {
    name: "Conv. Rate",
    columnName: "conversionRate",
    suffix: "%",
    orderDisabled: false,
  },
  {
    name: "Impressions",
    columnName: "impressions",
    isNumber: true,
    orderDisabled: false,
  },
  {
    name: "Ordered Units",
    columnName: "orderedUnits",
    isNumber: true,
    orderDisabled: false,
  },
  {
    name: "Orders",
    columnName: "orders",
    isNumber: true,
    orderDisabled: false,
  },
  {
    name: "Total Ordered Units",
    columnName: "totalOrderedUnits",
    isNumber: true,
    orderDisabled: false,
  },
  {
    name: "Total Orders",
    columnName: "totalOrders",
    isNumber: true,
    orderDisabled: false,
  },
  { name: "ROAS", columnName: "roas", orderDisabled: false },
  { name: "TACOS", columnName: "tacos", suffix: "%", orderDisabled: false },
];

const ProductsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { productsData } = useProductData();
  const [filteredAndSortedData, setFilteredAndSortedData] = useState([]);
  const [currentSort, setCurrentSort] = useState("sku");
  const [currentSortOrder, setCurrentSortOrder] = useState("asec");

  const [selectedHeaders, setSelectedHeaders] = useState(
    TableHeaders.slice(0, 6)
  );

  const toggleSelection = (name, checked) => {
    console.log(name, checked);

    if (!checked) {
      const found = selectedHeaders.some((el) => el.name === name);
      if (found) {
        let changedHeader = selectedHeaders.filter((selectedHeaders) => {
          return selectedHeaders.name !== name;
        });
        setSelectedHeaders(changedHeader);
      }
      // let index = selectedHeaders.findIndex((a) => a.name === name);
      // if (found) {
      //   selectedHeaders.splice(
      //     selectedHeaders.findIndex((a) => a.name === name),
      //     1
      //   );
      // }
      console.log(selectedHeaders);
    } else {
      const found = selectedHeaders.some((el) => el.name === name);
      if (!found) {
        const header = TableHeaders.filter((TableHeaders) => {
          return TableHeaders.name === name;
        });
        setSelectedHeaders((prev) => [...prev, header[0]]);
        console.log("saved");
      }
    }
  };

  const handleHeaderSortClick = (toSort) => {
    if (currentSort === toSort)
      setCurrentSortOrder(currentSortOrder === "asec" ? "desec" : "asec");
    else {
      setCurrentSort(toSort);
      setCurrentSortOrder("asec");
    }
  };

  useEffect(() => {
    const products = productsData.data;
    if (currentSort) {
      if (typeof products[0][currentSort] === "string") {
        products.sort((a, b) =>
          currentSortOrder === "asec"
            ? a[currentSort].localeCompare(b[currentSort])
            : b[currentSort].localeCompare(a[currentSort])
        );
      } else {
        products.sort((a, b) =>
          currentSortOrder === "asec"
            ? a[currentSort] - b[currentSort]
            : b[currentSort] - a[currentSort]
        );
      }
      setFilteredAndSortedData(products);
    } else {
      setFilteredAndSortedData(products);
    }
  }, [currentSort, productsData, currentSortOrder]);

  return (
    <SidebarDashGridLayout headerName="Products">
      <div className="col-span-12 flex flex-col h-full bg-[#F7F7F7] border-2 border-[#C4C4C4] rounded-lg p-3">
        <FilterSection
          TableHeaders={TableHeaders}
          toggleSelection={toggleSelection}
          selectedHeaders={selectedHeaders}
        />
        <div className="overflow-x-auto min-h-[500px] relative">
          <StyledTable className="w-full text-sm text-left text-black table-fixed">
            <thead className="text-xs">
              <tr>
                {selectedHeaders.map((header, id) => {
                  return (
                    <TableColumn
                      key={id}
                      isFixed={header.isFixed}
                      isResizable={header.isResizable}
                      intitialWidth={header.initialWidth}
                      onClick={() => {
                        handleHeaderSortClick(header.columnName);
                      }}
                      scope="col"
                      className="min-w-[300px] select-none"
                    >
                      <div className="flex justify-between font-medium">
                        {header.name}
                        {currentSort === header.columnName &&
                          currentSortOrder === "asc" && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>▲</span>
                              <span>▽</span>
                            </div>
                          )}
                        {currentSort === header.columnName &&
                          currentSortOrder === "desc" && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>△</span>
                              <span>▼</span>
                            </div>
                          )}
                        {currentSort !== header.columnName &&
                          (currentSortOrder === "desc" ||
                            currentSortOrder === "asc") && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>△</span>
                              <span>▽</span>
                            </div>
                          )}
                      </div>
                    </TableColumn>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedData?.map((product, id) => {
                return (
                  <tr key={id}>
                    {selectedHeaders.map((header, id) => {
                      if (id === 0) {
                        return (
                          <td
                            key={id}
                            className="py-4 px-3 font-normal text-sm text-gray-900 bg-white z-10 border-r-2 border-gray-300 flex flex-row gap-x-5"
                          >
                            <img
                              className="h-20 w-20 m-auto border-solid border border-gray-300 rounded-lg drop-shadow-md"
                              src={`https://api.lorem.space/image/furniture?w=100&h=100&id=${parseInt(
                                Math.random() * 100
                              )}`}
                              alt=""
                            />
                            {product[header.columnName]}
                          </td>
                        );
                      } else {
                        return (
                          <td key={id} className="py-4 px-3">
                            {generateValue(product, header)}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        productData={productsData}
      />
    </SidebarDashGridLayout>
  );
};

export default ProductsPage;

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 1em;
  td,
  th {
    padding: 8px;
  }

  tr {
    /* border: 1px solid black; */
    margin: 10px;
  }
  tr:hover {
    background-color: #f2e2f2;
  }

  th {
    /* padding-top: 12px; */
    /* padding-bottom: 12px; */
    text-align: left;
    background-color: #e4e4e4;
    color: black;
  }
  thead {
    td,
    th {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    td,
    th {
      background: white;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
    }
  }
`;
