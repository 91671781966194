import { Listbox, Transition } from "@headlessui/react";
import "./style.css";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import React, { useEffect, Fragment } from "react";

const ColumnsButton = ({ TableHeaders, toggleSelection, selectedHeaders }) => {
  useEffect(() => {
    console.log(selectedHeaders);
  }, [selectedHeaders]);

  useEffect(() => {
    console.log(selectedHeaders);
  }, [selectedHeaders]);

  return (
    <>
      <Listbox>
        <div className="relative  z-25">
          <Listbox.Button className="relative w-full cursor-default pl-3 pr-10 text-left  sm:text-sm px-5 py-2 rounded bg-white border border-gray-300 flex flex-row items-center gap-x-3 text-sm font-medium">
            <div class="bg-white  flex flex-row items-center gap-x-3 text-sm font-medium">
              <AdjustmentsIcon className="w-5 h-5" />
              Columns
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60  p-4 w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm hide-scrollbar">
              {TableHeaders.map((headers, headersIdx) => (
                // <Listbox.Option key={headerId}></Listbox.Option>
                <div className="items-center p-2 rounded hover:bg-gray-100 flex flex-row justify-between gap-x-3">
                  <input
                    id="checkbox-item-4"
                    type="checkbox"
                    value={headers}
                    disabled={headers.orderDisabled ? true : false}
                    defaultChecked={selectedHeaders.some(
                      (el) => el.name === headers.name
                    )}
                    onClick={(e) => {
                      toggleSelection(headers.name, e.target.checked);
                    }}
                    className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300   "
                  />
                  <label
                    for="checkbox-item-4"
                    className="ml-2 w-full text-sm font-medium text-gray-900 rounded "
                  >
                    {headers.name}
                  </label>
                </div>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
};

export default ColumnsButton;
