import { motion } from "framer-motion";
import { FunnelItem } from "./FunnelItem";

const cardAnimation = {
  animation: {
    opacity: 1,
    y: 0,
    transition: {
      // staggerChildren: .1,
      type: "spring",
      bounce: 0,
    },
  },
  hover: { scale: 1.1 },
  hidden: { opacity: 0, y: 200 },
};

function AdPerformanceFunnel({ dashData }) {
  const funnelItems = [
    {
      name: "Impressions",
      id: 1,
      value: dashData?.data?.impressions,
      nextValue: dashData?.data?.clicks,
      change: dashData?.data?.orderVolumeChange,
      funnelBridge: {
        name: "CTR",
        value: `${dashData?.data?.ctr}%`,
      },
      funnelColor: "#3CD0F9",
    },
    {
      name: "Clicks",
      id: 2,
      value: dashData?.data?.clicks,
      nextValue: dashData?.data?.orderVolume,
      change: dashData?.data?.totalAdSpendChange,
      funnelBridge: {
        name: "Conv.",
        value: `${dashData?.data?.conversionRate}%`,
      },

      funnelColor: "#8BE4FC",
    },
    {
      id: 3,
      name: "Orders",
      value: dashData?.data?.orderVolume,
      nextValue: dashData?.data?.orderVolume,
      change: dashData?.data?.orderUnitsChange,
      funnelBridge: false,
      funnelColor: "#D2F5FF",
    },
  ];
  console.log(funnelItems);
  return (
    <motion.div
      variants={cardAnimation}
      animate="animation"
      initial="hidden"
      className="order-9 md:order-8 col-span-12  sm:col-span-6 md:col-span-7 lg:col-span-4 xl:col-span-3 row-span-1 flex flex-col gap-2"
    >
      <p className="text-[12px] 2xl:text-base font-semibold">
        Ad Performance Funnel
      </p>
      <div className="grid grid-cols-3 gap-2 p-2 bg-[#F4F6FA] rounded-2xl h-full">
        {funnelItems.map((item, id) => {
          return <FunnelItem item={item} key={id} />;
        })}
      </div>
    </motion.div>
  );
}

export default AdPerformanceFunnel;
