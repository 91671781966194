import { Transition } from '@headlessui/react'

function NavigationToolTip({ show, content, isComingSoon }) {
    return (
        <Transition
            appear={true}
            show={true}
            enter="transition-all duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className='absolute -right-2 top-1/2 text-xs 2xl:text-sm transform -translate-y-1/2 translate-x-full p-2 bg-white rounded-md shadow whitespace-nowrap z-50'
            as="div"
        >
            { content } { isComingSoon && <span className='text-[5px] font-bold'>(Coming soon)</span>}
        </Transition>
    );
}

export default NavigationToolTip;