import React, { useState } from 'react';
import BottomLinks from './BottomLinks';
import NavLinks from './NavLinks';
import NavLogo from './NavLogo';

export function DesktopNavigation({ links }) {
    let [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div className={`${isCollapsed ? 'w-[100px] 2xl:w-36 min-w-0' : 'min-w-[200px] max-w-[400px] w-2/12 xl:w-3/12'} hidden md:block sticky top-0 h-[100vh] p-4 2xl:p-5 transition-all will-change-auto`}>
            <nav className='flex flex-col bg-[#FC58A5] p-4 2xl:p-6 rounded-xl 2xl:rounded-3xl h-full'>
                <NavLogo isCollapsed={isCollapsed} />
                <NavLinks links={links} isCollapsed={isCollapsed} />
                <BottomLinks isCollapsed={isCollapsed} setIsCollpased={setIsCollapsed} />
            </nav>
        </div>
    );
}
