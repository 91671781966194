import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  CollectionIcon,
} from "@heroicons/react/outline";

function CampaignStateSelector({ stateFilter, setStateFilter, stateOptions }) {
  return (
    <div className="relative z-10 whitespace-nowrap">
      <Listbox value={stateFilter} onChange={setStateFilter}>
        <Listbox.Button className="flex gap-1 text-black bg-white p-2 pr-6 border border-[#d2d2d2] rounded-md text-xs relative">
          <CollectionIcon className="w-4 h-4 text-black" />
          {stateFilter.name}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute -bottom-1 left-0 bg-white border border-[#c2c2c2] rounded-lg w-max mt-1 text-sm overflow-hidden cursor-pointer translate-y-full">
          {stateOptions.map((item, id) => {
            return (
              <Listbox.Option
                className={`relative select-none py-2 pl-10 pr-4 hover:bg-pink-50  ${
                  stateFilter.name === item.name
                    ? "bg-pink-100 text-pink-900"
                    : "text-gray-900"
                }`}
                key={id}
                value={item}
              >
                {item.name}
                {stateFilter.name === item.name ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </Listbox>
    </div>
  );
}

export default CampaignStateSelector;
