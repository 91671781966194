import React from 'react';
import dashLogoTextOnly from '../../../assets/img/xena-text-only-white.svg';

function NavLogo({ isCollapsed }) {
    return (
        <div className='flex items-center'>
            <img className="w-9 2xl:w-20" src={dashLogoTextOnly} alt="" />
        </div>
    );
}

export default NavLogo;