import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/outline';
import React from 'react';

function TrendPillBox({  trendPercentage, isTrendUp }) {
    return (
        <div className='flex items-center px-1 py-1 xl:px-2 xl:py-1 2xl:px-4 2xl:py-2 bg-black rounded-3xl'>
            {
                isTrendUp ? 
                <>
                    <TrendingUpIcon className='mr-0.5 xl:mr-1.5 2xl:mr-2 w-3 h-3 2xl:w-6 2xl:h-6 text-[#00FFAB]' />
                    <p className='text-[#00FFAB] text-[8px] xl:text-xs 2xl:text-xl'>{trendPercentage}%</p>
                </>
                : 
                <>
                    <TrendingDownIcon className='mr-0.5 xl:mr-1.5 2xl:mr-2 w-3 h-3 2xl:w-6 2xl:h-6 text-[#ff0000]' />
                    <p className='text-[#ff0000] text-[8px] xl:text-xs 2xl:text-xl font-medium'>{trendPercentage}%</p>
                </>
            }
        </div>
    );
}

export default TrendPillBox;