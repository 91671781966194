// import { ArrowTrendingUpIcon } from "@heroicons/react/solid";
import { AnimatedCounter } from "../Common/AnimatedCounter";
import { HorizontalFunnelChart } from "./HorizontalFunnelChart";

const formatValue = (n) => {
  if (n < 1e3) return { value: n, suffix: "" };
  if (n >= 1e3 && n < 1e6) return { value: +(n / 1e3).toFixed(1), suffix: "K" };
  if (n >= 1e6 && n < 1e9) return { value: +(n / 1e6).toFixed(1), suffix: "M" };
  if (n >= 1e9 && n < 1e12)
    return { value: +(n / 1e9).toFixed(1), suffix: "B" };
  if (n >= 1e12) return { value: +(n / 1e12).toFixed(1), suffix: "B" } + "T";
};

export const FunnelItem = ({ item }) => {
  const data = formatValue(item?.value);
  return (
    <div className="rounded-xl bg-[#D2F5FF] col-span-1 flex flex-col items-center">
      <div className="p-3 flex flex-col items-center">
        <p className="text-xs font-semibold">{item?.name}</p>
        <div className="flex text-xl font-Raleway">
          <AnimatedCounter from={0} to={data?.value} />
          <p>{data?.suffix}</p>
        </div>
        <p className="flex font-bold font-Raleway text-sm">
          {/* <ArrowTrendingUpIcon className="w-5 h-5 text-black" /> */}
          {item?.change}%
        </p>
      </div>
      {item?.funnelBridge ? (
        <div className="z-10 ml-[100%] bg-white border border-gray-500 rounded-lg flex gap-2 justify-between p-1 text-xs">
          <p className="font-semibold">{item?.funnelBridge?.name}</p>
          <p className="text-gray-500">{item?.funnelBridge?.value}</p>
        </div>
      ) : (
        <div className="h-12" />
      )}
      <HorizontalFunnelChart
        id={item.id}
        points={[item?.value, item.nextValue]}
      />
    </div>
  );
};
