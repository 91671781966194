import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth"

const RequireAuth = () => {
    const { authState } = useAuth();
    const location = useLocation();

    return (
        authState?.accessToken
            ? <Outlet />
            : <Navigate to="/signIn" state={{ from: location }} replace />
    )
}

export default RequireAuth;