import { Tab } from "@headlessui/react";
import { useEffect } from "react";
import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  {
    name: "30 Days",
    days: 30,
  },
  {
    name: "60 Days",
    days: 60,
  },
  {
    name: "90 Days",
    days: 90,
  },
  {
    name: "Custom",
  },
];

function LeadTimeSelector({ setLeadTime }) {
  const [isCustomLeadTime, setIsCustomLeadTime] = useState(false);
  const [customLeadTime, setCustomLeadTime] = useState(false);
  const debouncedValue = customLeadTime;

  useEffect(() => {
    if (debouncedValue) {
      setLeadTime(parseInt(debouncedValue));
    }
  }, [debouncedValue, setLeadTime]);

  return (
    <div className="flex gap-2 items-center">
      <p className="text-sm font-bold">Lead Time:</p>
      <Tab.Group
        onChange={(id) => {
          if (tabs[id].name === "Custom") {
            setLeadTime(40);
            setIsCustomLeadTime(true);
          } else {
            setIsCustomLeadTime(false);
            setLeadTime(tabs[id].days);
          }
        }}
      >
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1">
          {tabs.map((tab, id) => {
            return (
              <Tab
                key={id}
                className={({ selected }) =>
                  classNames(
                    "w-full whitespace-nowrap rounded-lg py-1 px-2 xl:py-2 xl:px-5 text-[8px] md:text-xs lg:text-sm font-medium leading-5 text-gray-500",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-pink-400 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow text-pink-500"
                      : "text-gray-400 hover:bg-white/[0.12] hover:text-black"
                  )
                }
              >
                {tab.name}
              </Tab>
            );
          })}
        </Tab.List>
      </Tab.Group>
      {isCustomLeadTime && (
        <div className="flex items-center gap-2 text-[10px] lg:text-sm">
          <input
            min={1}
            max={100}
            onChange={(e) => setCustomLeadTime(e.target.value)}
            defaultValue={100}
            type="number"
            name="custom-lead-time"
            className="w-14 p-2 border text-black text-[10px] lg:text-sm rounded-md"
          />
          days
        </div>
      )}
    </div>
  );
}

export default LeadTimeSelector;
