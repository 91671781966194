import OrderVolume from "./OrderVolume";
import SalesCard from "./SalesCard";
import TopSellingSKUs from "./TopSellingSkus";
import TotalAdSpend from "./TotalAdSpend";
import { XenaAISection } from "./XenaAI";
import AdPerformanceFunnel from "./AdPerformanceFunnel";
import OrderedUnitsCard from "./OrderedUnitsCard";
import XenaNavigator from "./XenaNavigator";
import AdSalesCard from "./AdSales";
import TacosCard from "./TacosCard";

const DashboardCards = ({ dashData }) => {
  return (
    <>
      <SalesCard dashData={dashData} />
      <TotalAdSpend dashData={dashData} />
      <AdSalesCard dashData={dashData} />
      <OrderVolume dashData={dashData} />
      <TacosCard dashData={dashData} />
      <OrderedUnitsCard dashData={dashData} />
      <XenaAISection />
      <TopSellingSKUs dashData={dashData} />
      <AdPerformanceFunnel dashData={dashData} />
      <XenaNavigator />
      {/* <WeeklySalesData /> */}
      {/* <CampaignTable /> */}
    </>
  );
};

export default DashboardCards;
