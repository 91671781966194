import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

function CustomLink({ isActive, link }) {

    return <div className={`relative flex items-center rounded-md transition-colors my-1 hover:bg-white hover:text-black ${isActive ? "text-black bg-white" : "text-white"}`}>
            <div className={`w-8 h-8 overflow-hidden ${link.isDisabled && "opacity-30"}`}>
                { isActive ? link.icon : link.iconOutline }
            </div>
            <p className="flex w-full items-center text-[10px] font-semibold ml-1 leading-none">
                <span className={`${link.isDisabled && "opacity-30"}`}>{link.name}</span>
                {link.isComingSoon && <span className='ml-1 text-[6px] bg-white text-black py-[1px] px-1  rounded-full text-center'>Coming Soon</span>}
            </p>
            {/* <p className='text-xs ml-1 leading-none font-semibold'>{link.name}</p> */}
    </div>

}

function NavLinks({ links }) {

    return (
        <Transition appear={true} show={true}>
            <div className='mt-10'>
                {
                    links.map((link,id)=>{
                        return <>
                            <Transition.Child
                                enter={`transition ease-in-out duration-600 transform`} 
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in-out duration-600 transform"
                                leaveFrom="opcaity-100"
                                leaveTo="opacity-0"
                                appear={true}
                            >
                                <NavLink onClick={(e)=> { link.isDisabled && e.preventDefault() }} key={id} to={link.to} >
                                    {
                                        ({ isActive }) => (
                                            <CustomLink 
                                                isActive={isActive}
                                                link={link} 
                                            />    
                                        )
                                    }
                                </NavLink>
                            </Transition.Child>
                        </>
                    })
                }
            </div>
        </Transition>
    );
}

export default NavLinks;