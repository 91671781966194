import React from "react";
import loadingSvg from "../../assets/img/loading.svg";
// import { Link } from "react-router-dom";

export function SignUpForm(props) {
  return (
    <div className="max-w-sm p-6 sm:p-10 flex flex-col items-center">
      {/* <img className="w-44" src={logoColor} alt="Xena Intelligence Logo" />
      <p className="my-6 text-xl font-semibold text-center">
        Welcome back to <span className="text-accent">XENA</span>!
      </p> */}
      <div className="p-5 bg-white flex flex-col rounded-lg shadow-lg">
        <h3 className="text-md xl:text-2xl font-semibold mb-1 xl:mb-3">
          Login
        </h3>
        {/* <CustomGoogleLogin /> */}
        <form
          action=""
          autoComplete="off"
          onChange={props.handleFormOnChange}
          onSubmit={props.handleOnSubmit}
        >
          <div className="w-full mt-4">
            <label className="font-semibold text-sm" htmlFor="email">
              E-mail
            </label>
            <input
              className="p-3 text-base w-full h-8 border border-black-100 focus:border-black focus:shadow-md  hover:shadow-md transition-all ease-in-out rounded-md"
              type="email"
              name="email"
              value={props.loginData.email}
            />
          </div>
          <div className="w-full mt-4">
            <label className="font-semibold text-sm" htmlFor="password">
              Password
            </label>
            <input
              className="p-3 text-base w-full h-8 border border-black-100 focus:border-black focus:shadow-md  hover:shadow-md transition-all ease-in-out  rounded-md"
              type="password"
              name="password"
              value={props.loginData.password}
            />
          </div>
          <div className="w-full mt-3">
            {props.apiErrorMsg && (
              <p className="text-center text-sm text-red-600 font-semibold">
                {props.apiErrorMsg}
              </p>
            )}
            <button
              className="mt-3 p-2 rounded w-full bg-black font-semibold text-white cursor-pointer hover:shadow-sm transition-all ease-in-out"
              type="submit"
            >
              {props.isLoading ? (
                <div className="flex items-center justify-center text-white">
                  <img
                    className="h-8 mr-2 text-white"
                    src={loadingSvg}
                    alt=""
                  />
                  <p>Logging In</p>
                </div>
              ) : (
                "Login"
              )}
            </button>
            <div className="flex justify-end mt-4">
              {/* <Link to="/forgotPassword">
                <p className="text-xs font-semibold underline">
                  Forgot Password?
                </p>
              </Link> */}
              <a
                href="http://xenaintelligence.com/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-xs font-semibold underline">
                  Create a new account.
                </p>
              </a>
            </div>
          </div>
        </form>
      </div>
      {/* <div className="flex flex-col mt-10 self-start">
        <p className="text-md font-semibold">Test Credentials:</p>
        <div className="flex flex-col mt-2">
          <p className="text-sm mb-4 mt-2">
            E-mail:
            <span className="bg-pink-100 ml-2 px-2 py-2 rounded-3xl">
              demo@xenaintelligence.com
            </span>
          </p>
          <p className="text-sm">
            Password:
            <span className="bg-pink-100 ml-2 px-2 py-1 rounded-3xl">
              XenaIsAwesome
            </span>
          </p>
        </div>
      </div> */}
    </div>
  );
}
