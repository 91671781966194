import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

export function LineChart({ adsales }) {
  function createGradient(ctx, area) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0, "#DEF5ED");
    gradient.addColorStop(1, "#48DA94");
    return gradient;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        display: false,
        ticks: {
          display: false,
          padding: 0,
          maxRotation: 0,
          // beginAtZero: true
          // suggestedMin: 100000,
        },
      },
      x: {
        display: false,
        ticks: {
          display: false,
          padding: -50,
          maxRotation: 0,
          maxTicksLimit: 20,
          // sampleSize: 10,
          beginAtZero: true,
        },
      },
    },
    layout: {
      padding: 0,
    },
  };

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const labels = [
      "2022-12-30",
      "2022-12-31",
      "2023-01-01",
      "2023-01-02",
      "2023-01-03",
      "2023-01-04",
      "2023-01-05",
      "2023-01-06",
      "2023-01-07",
      "2023-01-08",
      "2023-01-09",
      "2023-01-10",
      "2023-01-11",
    ];
    const data = {
      labels,
      datasets: [
        {
          label: "Sales",
          data: Array.from({ length: 13 }, () =>
            Math.floor(Math.random() * 40 + adsales)
          ),
          borderColor: "rgb(0, 0, 0)",
          borderWidth: 1,
          fill: true,
          cubicInterpolationMode: "monotone",
          pointRadius: 1.5,
        },
      ],
    };

    const tempchartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };
    setChartData(tempchartData);
  }, [adsales]);

  return (
    <div className="w-full ">
      <Line ref={chartRef} options={options} data={chartData} />
    </div>
  );
}
