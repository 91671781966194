import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { useState } from "react";

export const BidModificationCell = ({ product }) => {
  const [modifiedBid, setModifiedBid] = useState(product["bid"]);
  const [successBid, setSuccessBid] = useState(null);
  const [isBidEditMode, setIsBidEditMode] = useState(false);

  const modifyBid = () => {
    const controller = new AbortController();
    setIsBidEditMode(false);
    setSuccessBid(modifiedBid);
    return () => {
      controller.abort();
    };
  };

  return (
    <div>
      {!isBidEditMode && (
        <div
          onClick={() => setIsBidEditMode(true)}
          className="p-2 rounded-xl bg-pink-50 flex justify-between"
        >
          <p className="text-xs md:text-sm">${successBid || product["bid"]}</p>
          <p className="text-[9px]">(tap to edit)</p>
        </div>
      )}

      {isBidEditMode && (
        <div className="flex items-center w-full">
          <div className="flex items-center">
            $
            <input
              onChange={(e) => setModifiedBid(parseFloat(e.target.value))}
              className="w-full p-1"
              type={"number"}
              defaultValue={parseFloat(product["bid"])}
            />
          </div>
          <button
            onClick={modifyBid}
            className="p-1 bg-green-100 hover:bg-green-200"
          >
            <CheckIcon className="w-5 h-5 text-black" />
          </button>
          <button
            onClick={() => setIsBidEditMode(false)}
            className="p-1 bg-red-100 hover:bg-red-200"
          >
            <XIcon className="w-5 h-5 text-black" />
          </button>
        </div>
      )}
    </div>
  );
};
