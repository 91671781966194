import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import NavigationToolTip from './NavigationToolTip';

function CustomLink({ isCollapsed, isActive, link }) {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
      setIsHovering(true);
    };
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    return <div 
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver} 
        className={`relative flex ${isCollapsed && 'justify-center w-full'} items-center rounded-md 2xl:rounded-xl transition-colors ${isActive ? "text-black" : "text-white"} my-2 2xl:my-3 hover:bg-white hover:text-black ${isActive && "bg-white"} ${link.isDisabled && "cursor-not-allowed"}`}
        >
            <div className={`w-8 h-8 2xl:w-12 2xl:h-12 overflow-hidden ${link.isDisabled && "opacity-30"}`}>
                { isActive ? link.icon : link.iconOutline }
            </div>
            {
                !isCollapsed &&
                <p className="flex justify-between w-full items-center text-[10px] xl:text-xs 2xl:text-md font-semibold ml-1 2xl:ml-3 leading-none">
                    <span className={`${link.isDisabled && "opacity-30"}`}>{link.name}</span>
                    {link.isComingSoon && <span className='text-[6px] bg-white text-black py-[1px] px-1  rounded-full text-center'>Coming Soon</span>}
                </p>
            }
            {
                isHovering && isCollapsed && 
                <NavigationToolTip isComingSoon={link.isComingSoon} show={isHovering} content={link.name} />
            }
    </div>

}

function NavLinks({ isCollapsed, links }) {

    return (
        <div className='mt-5'>
            {
                links.map((link,id)=>{
                    return <NavLink 
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                        key={id} 
                        to={link.to}
                        onClick={(e)=>{ link.isDisabled && e.preventDefault() }}
                    >
                    {
                        ({ isActive }) => (
                            <CustomLink 
                                isActive={isActive}
                                isCollapsed={isCollapsed}
                                link={link} 
                            />    
                        )
                    }
                    </NavLink>
                })
            }
        </div>
    );
}

export default NavLinks;