import useDashData from '../../hooks/useDashData';
import SidebarDashGridLayout from '../../layouts/SidebarDashGridLayout';
import DashboardCards from './Cards';
import DashCardsLoading from './Cards/Common/DashCardsLoading';
import { DashHeader } from './DashHeader';


function Dashboard() {
    
    let {
        dashData,
        selectedDateRange,
        setSelectedDateRange,
        datesRange,
        selectedMarketPlace,
        setSelectedMarketPlace,
        marketplaces
    } = useDashData();
    
    return (
        <SidebarDashGridLayout headerName="Welcome!" >
            <DashHeader 
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                datesRange={datesRange}
                selectedMarketPlace={selectedMarketPlace}
                setSelectedMarketPlace={setSelectedMarketPlace}
                marketplaces={marketplaces}
            />
            {
                dashData.isLoading ?
                <DashCardsLoading />
                :
                <DashboardCards dashData={dashData} />
            }
        </SidebarDashGridLayout>
    );
}

export default Dashboard;