export const CheckListItem = ({ item, onHandleCheck }) => {
  return (
    <div
      onClick={() => onHandleCheck(item)}
      className={`flex gap-2 bg-white rounded-2xl p-3 cursor-pointer ${
        item.completed && "opacity-30"
      }`}
    >
      {!item.completed ? (
        <div className="w-5 h-5 flex-shrink-0 border-2 border-[#5939FB] rounded-full whitespace-nowrap" />
      ) : (
        <div className="w-5 h-5 flex-shrink-0 bg-[#5939FB] rounded-full" />
      )}
      <p className={`text-sm text-black ${item.completed && "line-through"}`}>
        {item.title}
      </p>
    </div>
  );
};
