import { Popover, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'

const notifications = [
  {
    name: 'Budget Optimization',
    description: `Campaign 'AUTO_KIT_SP' budget increased by 10% to $544`,
    href: '##',
  },
  {
    name: 'New Keyword',
    description: `New keyword 'disposable plates' identied and added to campaign 'MANUAL_BOX_SB'`,
    href: '##',
  },
]

export default function NotificationBell() {
  return (
    <div className="max-w-xs">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md mx-1 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <BellIcon
                className={`${open ? '' : 'text-opacity-70'}
                  h-7 w-7 text-black transform translate-y-1 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-3 w-screen max-w-sm  transform px-4 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white p-7">
                    {notifications.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-pink-500 focus-visible:ring-opacity-50"
                      >
                        <div className="">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}