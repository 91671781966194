import { FilterIcon } from "@heroicons/react/outline";
import { AddFilterButton } from "./AddFilterButton";
import LeadTimeSelector from "./LeadTimeSelector";

export function FilterSection({ leadTime, setLeadTime }) {
  return (
    <>
      <div className="z-10 flex flex-col sm:flex-row justify-between w-full gap-5">
        <div className="flex gap-3 items-center">
          <FilterIcon className="w-8 h-8 text-black" />
          <AddFilterButton />
        </div>
      </div>
      <div className="flex flex-col gap-2 z-10 bg-[#ECECEC] p-3 rounded-lg mt-3">
        <div className="flex flex-wrap gap-2 justify-between items-center w-full">
          <div className="flex gap-2 flex-wrap z-10">
            <LeadTimeSelector setLeadTime={setLeadTime} />
          </div>
        </div>
      </div>
    </>
  );
}
