import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { CardHeader } from "../../../Dashboard/Cards/Common/CardHeader";
import CompareTab from "./CompareTab";

export const ExpandedCard = ({ setIsExpanded, campaignData }) => {
  let wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setIsExpanded(false);
  });

  let [isLoading] = useState(false);

  return (
    <motion.div
      initial={{ backdropFilter: "blur(0px)", opacity: 1 }}
      animate={{ backdropFilter: "blur(10px)", opacity: 1 }}
      exit={{ backdropFilter: "blur(0px)", opacity: 0 }}
      transition={{
        // staggerChildren: .1,
        type: "spring",
        bounce: 0,
      }}
      style={{ zIndex: 500 }}
      className="flex justify-center items-center fixed p-7 md:p-20 top-0 left-0 w-screen h-screen bg-[#00000062]"
    >
      <motion.div
        ref={wrapperRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{ zIndex: 500 }}
        // exit={{ opacity: 0 }}
        layoutId="order-volume-card"
        layout
        className="overflow-y-auto relative w-full min-w-[320px] max-w-[900px] min-h-[700px] max-h-[80vh] transform transition-all order-1 col-span-6 sm:col-span-3 row-span-2 bg-[#F4F6FA] rounded-2xl"
      >
        {!isLoading && (
          <div className="p-5 cursor-pointer ">
            <CardHeader headerName="Compare" />
            <div className="flex flex-col lg:flex-row gap-x-4 my-3">
              <CompareTab campaignData={campaignData} />
              <CompareTab campaignData={campaignData} />
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};
