import { PlusIcon } from "@heroicons/react/solid";

export function AddFilterButton() {
  return (
    <button
      disabled={true}
      className="disabled:cursor-not-allowed disabled:opacity-50 bg-white text-black flex items-center gap-2 px-3 py-2 rounded-md border border-[#C4C4C4] hover:text-white hover:bg-black transition-colors"
    >
      <PlusIcon className="w-6 h-6" />{" "}
      <p className="text-sm">
        Add filters <span className="text-[8px]">(Coming soon)</span>
      </p>
    </button>
  );
}
