import { createContext, useEffect, useReducer } from "react";
import useQueryParams from "../hooks/useQueryParams";
export const AuthContext = createContext();

const ACTIONS = {
  LOGIN: "login-action",
  LOGOUT: "logout-action",
};

const initialAuthState = {
  isAuthed: false,
  accessToken: null,
  userData: {
    id: 3,
    roleId: 2,
    firstName: "Slick",
    lastName: "Inc",
    email: "demo@xenaintelligence.com",
    password: "XenaIsAwesome",
    isConnected: true,
    isProfileSelected: true,
    keywordStrategy: "Conservative Strategy",
    createDate: "2022-02-01T23:36:05.086509",
    updateDate: null,
    logo: "./assets/img/users/user1/logo.png",
  },
};

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN:
      let { accessToken } = action.payload;
      localStorage.setItem("accessToken", accessToken);
      return { ...state, isAuthed: true, accessToken: accessToken };

    case ACTIONS.LOGOUT:
      localStorage.removeItem("accessToken");
      window.location.replace("/signIn");
      return { ...state, isAuthed: false, accessToken: null, userData: null };
    default:
      throw new Error("Not a valid action given for auth Reducer.");
  }
};

const AuthProvider = (props) => {
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
  const urlParams = useQueryParams();
  useEffect(() => {
    if (urlParams.skipLogin === "true")
      authDispatch({
        type: ACTIONS.LOGIN,
        payload: {
          accessToken: "xena-access-token",
        },
      });
  }, [urlParams]);
  return (
    <AuthContext.Provider
      value={{
        authState: authState,
        authDispatch: authDispatch,
        authActions: ACTIONS,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
