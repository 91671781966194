import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

export function AnimatedCounter({ from, to, className, prefix, suffix, precision }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = new Intl.NumberFormat('en-US').format(value.toFixed(value > 10 ? 0 : 2));
      },
      onComplete(){
        node.textContent = new Intl.NumberFormat('en-US').format(to.toFixed(precision ? precision : 2));
      }
    });

    return () => controls.stop();
  }, [from, to, precision]);

  return <p className={`${className}`}>{ prefix ? prefix : '' }<span ref={nodeRef} />{ suffix ? suffix : '' }</p>;
}