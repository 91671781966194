// import { MicrophoneIcon, SearchIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import XenaVoiceAI from "../../common/XenaVoiceAI";
import useAuth from "../../hooks/useAuth";
import NotificationBell from "./NotificationBell";
import XenaMascot from "../../assets/img/xena-mascot.svg";
import { MicrophoneIcon } from "@heroicons/react/outline";

export const GridSectionHeader = ({ headerName }) => {
  let [isDropDownVisible, setIsDropDownVisible] = useState(false);
  let [showXenaVoiceAI, setShowXenaVoiceAI] = useState(false);
  const { authState, authDispatch, authActions } = useAuth();
  let navigate = useNavigate();

  return (
    <div className="mx-5 my-2 lg:m-5 xl:m-6 flex items-center justify-between z-50 mb-0">
      <h1 className="hidden sm:block mr-3 text-lg lg:text-xl xl:text-3xl font-semibold uppercase">
        {headerName}
      </h1>
      <div className="flex items-center w-full sm:w-fit">
        <button
          onClick={() => {
            setShowXenaVoiceAI(true);
          }}
          className="p-1 w-7 h-7 bg-pink-600  rounded-full"
        >
          <MicrophoneIcon className="text-white w-full" />
        </button>
        <NotificationBell />
        <div
          onClick={() => {
            setIsDropDownVisible(!isDropDownVisible);
          }}
          className="relative px-2 rounded-xl flex items-center cursor-pointer hover:bg-gray-100"
        >
          <img
            className="mr-2 w-8 h-8 bg-gray-200 p-1 rounded-xl"
            src={XenaMascot}
            alt=""
          />
          {authState?.userData?.firstName ? (
            <p className="mr-2 select-none text-xs md:text-md xl:text-lg whitespace-nowrap">
              {authState?.userData?.firstName} {authState?.userData?.lastName}
            </p>
          ) : (
            <p className="mr-2 select-none whitespace-nowrap">No Username</p>
          )}
          <ChevronDownIcon className="w-3 h-3 select-none" />
          {isDropDownVisible && (
            <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-full bg-white rounded-xl shadow-lg">
              <button
                className="m-1 rounded-xl p-2 w-full text-black hover:bg-black hover:text-white"
                onClick={() => {
                  navigate("/signIn");
                  authDispatch({ type: authActions.LOGOUT });
                }}
              >
                <span className="select-none">Logout</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <AnimatePresence>
        {showXenaVoiceAI && (
          <XenaVoiceAI setShowXenaVoiceAI={setShowXenaVoiceAI} />
        )}
      </AnimatePresence>
    </div>
  );
};
