import { useRef, useState } from "react";

export const TableColumn = (props) => {
  const columnRef = useRef();
  const { intitialWidth, isResizable, isFixed, onClick } = props;
  const [startX, setStartX] = useState(null);
  const mouseDownHandler = (e) => {
    setStartX(e.clientX);
    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };
  const mouseMoveHandler = (e) => {
    console.log(e);
    if (startX) {
      const dx = e.clientX - startX;
      console.log(dx);
      console.log(startX);
      const newWidth = parseFloat(columnRef.current.width) + dx;
      columnRef.current.style.width = `${newWidth}px`;
    }
  };
  const mouseUpHandler = (e) => {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  return (
    <th
      // {...props}
      onClick={onClick}
      width={intitialWidth || 120}
      className={[
        props.className,
        `${isFixed ? "sticky left-0 bg-gray-300 z-10" : "relative"}`,
      ].join(" ")}
      ref={columnRef}
    >
      {props.children}
      {isResizable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={mouseDownHandler}
          className="h-full w-2 hover:bg-pink-500 absolute top-0 right-0 cursor-ew-resize"
        ></div>
      )}
    </th>
  );
};
