import React from 'react';

function HamMenuIcon({ isOpened, setIsOpened }) {
    return (
        <button onClick={() => { setIsOpened(!isOpened) }} className='relative w-6 h-4 flex flex-col justify-between transition-all'>
            <div className={`w-full h-[2px] trannsform transition-all rounded-md bg-white ${isOpened && 'absolute top-1/2 translate-y-1/2 rotate-45'}`} />
            <div className={`w-full h-[2px] trannsform transition-all rounded-md bg-white ${isOpened && 'opacity-0'}`} />
            <div className={`w-full h-[2px] trannsform transition-all rounded-md bg-white ${isOpened && 'absolute top-1/2 translate-y-1/2 -rotate-45'}`} />
        </button>
    );
}

export default HamMenuIcon;