import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { CardHeader } from "../Dashboard/Cards/Common/CardHeader";
import useOutsideClick from "../../hooks/useOutsideClick";
import { LineChart } from "./LineChart";

const ExpandedView = ({ setIsExpanded, viewDetail }) => {
  let [isLoading] = useState(false);
  let wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setIsExpanded(false);
  });
  console.log(viewDetail);
  return (
    <div>
      <motion.div
        initial={{ backdropFilter: "blur(0px)", opacity: 1 }}
        animate={{ backdropFilter: "blur(10px)", opacity: 1 }}
        exit={{ backdropFilter: "blur(0px)", opacity: 0 }}
        transition={{
          // staggerChildren: .1,
          type: "spring",
          bounce: 0,
        }}
        style={{ zIndex: 500 }}
        className="flex justify-center items-center fixed p-7 md:p-20 top-0 left-0 w-screen h-screen bg-[#00000062]"
      >
        <motion.div
          ref={wrapperRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ zIndex: 500 }}
          // exit={{ opacity: 0 }}
          layoutId="order-volume-card"
          layout
          className="relative w-full min-w-[320px] max-w-[900px] min-h-[700px] max-h-[80vh] transform transition-all order-1 col-span-6 sm:col-span-3 row-span-2 bg-[#F4F6FA] rounded-2xl overflow-y-auto"
        >
          {!isLoading && (
            <div className="p-5 cursor-pointer ">
              <CardHeader headerName="View Detail" />
              <div className="h-full border-2 my-4 p-2 bg-white rounded-xl">
                <div className="flex items-center gap-2 my-2">
                  <div className="p-2 rounded-lg bg-[#4496E3] font-medium text-white">
                    SP
                  </div>
                  <p className="font-medium text-black">
                    {viewDetail.campaignName}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className=" flex flex-col justify-between gap-y-1 p-2 row-span-2">
                    <div>
                      <LineChart adsales={viewDetail.adsales} />
                    </div>
                    <div>
                      <h1 className="text-md font-semibold">Adsales</h1>
                      <div className="flex flex-row justify-between">
                        <h1 className="text-lg text-gray-600">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(viewDetail.adsales)}
                        </h1>
                        <p className="text-green-600">+20%</p>
                      </div>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">ACOS</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {viewDetail.acos}%
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">ROAS</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {viewDetail.roas}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>

                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">ROAS</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {viewDetail.roas}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">Spend</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(viewDetail.spend)}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">Impressions</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {new Intl.NumberFormat("en-US").format(
                          viewDetail.impressions
                        )}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">Clicks</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {new Intl.NumberFormat("en-US").format(
                          viewDetail.clicks
                        )}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg ">
                    <h1 className="text-md font-semibold">Ordered Units</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {new Intl.NumberFormat("en-US").format(
                          viewDetail.orderedUnits
                        )}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>
                  <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
                    <h1 className="text-md font-semibold">Orders</h1>
                    <div className="flex flex-row justify-between">
                      <h1 className="text-lg text-gray-600">
                        {new Intl.NumberFormat("en-US").format(
                          viewDetail.orders
                        )}
                      </h1>
                      <p className="text-green-600">+20%</p>
                    </div>
                  </div>

                  <div className=" flex flex-row justify-around gap-x-4  col-span-2">
                    <div className="border-2 h-20 w-1/3 flex flex-col rounded-lg gap-y-1 p-2 ">
                      <h1 className="text-md font-semibold">Conv.Rate</h1>
                      <div className="flex flex-row justify-between ">
                        <h1 className="text-lg text-gray-600">
                          {viewDetail.conversionRate}%
                        </h1>
                        <p className="text-green-600">+20%</p>
                      </div>
                    </div>
                    <div className="border-2 h-20 w-1/3 flex flex-col rounded-lg gap-y-1 p-2">
                      <h1 className="text-md font-semibold">CPC</h1>
                      <div className="flex flex-row justify-between">
                        <h1 className="text-lg text-gray-600">
                          ${viewDetail.cpc}
                        </h1>
                        <p className="text-green-600">+20%</p>
                      </div>
                    </div>
                    <div className="border-2 h-20 w-1/3 flex flex-col rounded-lg gap-y-1 p-2">
                      <h1 className="text-md font-semibold">CTR</h1>
                      <div className="flex flex-row justify-between">
                        <h1 className="text-lg text-gray-600">
                          {viewDetail.ctr}%
                        </h1>
                        <p className="text-green-600">+20%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ExpandedView;
