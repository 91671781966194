import { useState } from "react";

export const TableRow = ({ campaign, generateValue, selectedHeaders }) => {
  const [isXenaOptimised, setIsXenaOptimised] = useState(Math.random() >= 0.5);
  return (
    <tr className={`relative ${isXenaOptimised && "xena-optimised-style"}`}>
      {selectedHeaders.map((header, id) => {
        return (
          <td key={id}>
            {generateValue(
              campaign,
              header,
              isXenaOptimised,
              setIsXenaOptimised
            )}
          </td>
        );
      })}
    </tr>
  );
};
