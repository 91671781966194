import { Switch } from "@headlessui/react";
import { CogIcon, XIcon } from "@heroicons/react/solid";
import { useState } from "react";

export const XenaOptimisedStateIndicator = ({
  isXenaOptimised,
  setIsXenaOptimised,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleClick = () => {
    setIsXenaOptimised(!isXenaOptimised);
  };

  return (
    <div className="flex flex-col bg-gray-200 p-1 rounded-md hover:bg-gray-300  cursor-pointer gap-1">
      <div className="flex justify-between">
        <Switch
          checked={isXenaOptimised}
          onChange={handleClick}
          className={`${
            isXenaOptimised ? "bg-pink-600" : "bg-gray-400"
          } relative inline-flex h-4 w-7 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              isXenaOptimised ? "translate-x-3" : "translate-x-0"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        <div onClick={() => setIsEditMode(!isEditMode)}>
          {isEditMode ? (
            <XIcon className="w-5 h-5 text-black" />
          ) : (
            <CogIcon className="w-5 h-5 text-black" />
          )}
        </div>
      </div>
      {!isEditMode ? (
        <p className="text-xs font-normal whitespace-nowrap">
          Target ACOS: 2.3
        </p>
      ) : (
        <div className="flex flex-col gap-1">
          <p className="text-xs">Target ACOS:</p>
          <div className="flex gap-2">
            <input
              className="p-1 w-full text-lg border border-gray-300 rounded-md"
              type={"number"}
            />
            <button className="py-1 px-2 bg-black text-white rounded-lg">
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
