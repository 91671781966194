import { LayoutGroup, motion } from "framer-motion";
import { AnimatedCounter } from "../Common/AnimatedCounter";
import { CardHeader } from "../Common/CardHeader";

const cardAnimation = {
  animation: {
    opacity: 1,
    y: 0,
    transition: {
      // staggerChildren: .1,
      type: "spring",
      bounce: 0,
    },
  },
  hover: { scale: 1.1 },
  hidden: { opacity: 0, y: 200 },
};

function TacosCard({ dashData }) {
  return (
    <LayoutGroup>
      <motion.div
        variants={cardAnimation}
        animate="animation"
        initial="hidden"
        className="col-span-6 sm:col-span-3 lg:col-span-3 order-5 row-span-1 bg-[#F4F6FA] rounded-2xl"
      >
        <div className="h-full flex flex-col justify-between p-2 xl:p-3">
          <CardHeader headerName="TACOS" />

          <AnimatedCounter
            from={0}
            to={dashData?.data?.tacos}
            suffix="%"
            className="text-3xl 2xl:text-4xl my-2 font-Raleway"
          />
        </div>
      </motion.div>
    </LayoutGroup>
  );
}

export default TacosCard;
