import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { useLocation } from "react-router";
import { SignUpForm } from "./SignUpForm";
import styled from "styled-components";
import { AnimatedGraphicSection } from "./AnimatedGraphicSection";

function SignInPage() {
  let [isLoading, setIsLoading] = useState(false);
  let [loginData, setLoginData] = useState({
    email: "demo@xenaintelligence.com",
    password: "XenaIsAwesome",
  });
  let [apiErrorMsg, setApiErrorMsg] = useState(null);

  const { authState, authDispatch, authActions } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();

  let handleFormOnChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  let handleFormValidation = () => {
    if (!loginData.email && !loginData.password) {
      setApiErrorMsg("Email & Password should not be empty!");
      return false;
    } else if (!loginData.email) {
      setApiErrorMsg("Email should not be Empty!");
      return false;
    } else if (!loginData.password) {
      setApiErrorMsg("Password should not be Empty!");
      return false;
    } else {
      return true;
    }
  };

  let handleOnSubmit = (e) => {
    setApiErrorMsg(null);
    setIsLoading(true);
    e.preventDefault();

    if (handleFormValidation()) {
      const accessToken = "xena-access-token";
      if (
        loginData.email === authState.userData.email &&
        loginData.password === authState.userData.password
      ) {
        authDispatch({
          type: authActions.LOGIN,
          payload: {
            accessToken: accessToken,
          },
        });
      } else {
        setApiErrorMsg("User Name and Password does not match.");
      }
    }
  };

  // redirect after authentication
  useEffect(() => {
    if (
      authState?.isAuthed &&
      authState?.accessToken &&
      authState?.userData?.email
    ) {
      navigate(from, { replace: true });
    }
  }, [authState, navigate, from]);

  return (
    <section className="min-h-screen flex justify-center items-center">
      <div className="flex flex-col-reverse sm:flex-row shadow-2xl rounded-xl  overflow-hidden m-10">
        <StyledAnimatedGradient className="w-full sm:w-1/2 flex items-center justify-center">
          <SignUpForm
            isLoading={isLoading}
            apiErrorMsg={apiErrorMsg}
            handleFormOnChange={handleFormOnChange}
            handleOnSubmit={handleOnSubmit}
            loginData={loginData}
          />
        </StyledAnimatedGradient>
        <AnimatedGraphicSection />
      </div>
    </section>
  );
}

export default SignInPage;

let StyledAnimatedGradient = styled.div`
  background: linear-gradient(270deg, #ff46a0, #a687ff, #ff46a0);
  background-size: 600% 600%;
  -webkit-animation: animated-gradient 24s ease infinite;
  -moz-animation: animated-gradient 24s ease infinite;
  animation: animated-gradient 24s ease infinite;

  @-webkit-keyframes animated-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes animated-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes animated-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
