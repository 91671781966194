import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SplashScreen from "./components/common/SplashScreen";
import useAuth from "./components/hooks/useAuth";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [msg,setMsg]= useState(true);
  const { authDispatch, authActions } = useAuth();

  useEffect(() => {
    let storedAccessToken = localStorage.getItem("accessToken");
    const verifyAuthToken = async () => {
      if (storedAccessToken && storedAccessToken === "xena-access-token") {
        authDispatch({
          type: authActions.LOGIN,
          payload: {
            accessToken: storedAccessToken,
          },
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else if (
        storedAccessToken &&
        storedAccessToken !== "xena-access-token"
      ) {
        // setMsg("Token Expired, Please Login.");
        setTimeout(() => {
          setIsLoading(false);
          authDispatch({ type: authActions.LOGOUT });
        }, 2000);
      } else {
        setIsLoading(false);
      }
    };
    storedAccessToken ? verifyAuthToken() : setIsLoading(false);
  }, [authDispatch, authActions]);

  return (
    <>
      <AnimatePresence>
        {isLoading ? <SplashScreen /> : <Outlet />}
      </AnimatePresence>
    </>
  );
};

export default PersistLogin;
