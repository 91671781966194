import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

function PageButton({ setCurrentPage, currentPage, toPage }) {
  return (
    <button
      onClick={() => {
        setCurrentPage(toPage);
      }}
      className={`py-2 px-3 leading-tight text-gray-500 bg-white border ${
        currentPage === toPage
          ? "text-pink-600 bg-pink-50 border border-pink-300 hover:bg-pink-100 hover:text-pink-700 "
          : "border-gray-300 hover:bg-gray-100 hover:text-gray-700"
      }`}
    >
      {toPage}
    </button>
  );
}

export function Pagination({ productData, currentPage, setCurrentPage }) {
  return (
    <nav
      className="flex justify-between items-center m-3 w-full col-span-12"
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-500">
        Showing <span className="font-semibold text-gray-900">1</span> of{" "}
        <span className="font-semibold text-gray-900">1</span>
      </span>

      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:opacity-40 disabled:cursor-not-allowed">
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
        </li>
        <li>
          <PageButton
            currentPage={1}
            toPage={1}
            setCurrentPage={setCurrentPage}
          />
        </li>

        <li>
          <button className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </li>
      </ul>
    </nav>
  );
}
