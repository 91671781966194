import { LayoutGroup, motion } from "framer-motion";
import { useState } from "react";
import { CheckListItem } from "./CheckListItem";
import { CircleProgress } from "./CircleProgress";

const cardAnimation = {
  animation: {
    opacity: 1,
    y: 0,
    transition: {
      // staggerChildren: .1,
      type: "spring",
      bounce: 0,
    },
  },
  hover: { scale: 1.08 },
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      // staggerChildren: .1,
      type: "spring",
      bounce: 0,
    },
  },
};

const items = [
  {
    id: 1,
    completed: false,
    title:
      "“CAMP_1” campaign is performing with ROAS 0.2. Turn on XENA optimistion for “CAMP_1” campaign to improve ROAS.",
  },
  {
    id: 2,
    completed: false,
    title: "Connect SP APIs",
  },
  {
    id: 3,
    completed: false,
    title: `You product "Bean Bag XXL 2pack" is out of stock. Please replenish it`,
  },
  {
    id: 4,
    completed: true,
    title:
      "“CAMP4” campaign is performing with ROAS 0.2. Turn on XENA optimistion for “CAMP_1” campaign to improve ROAS.",
  },
  {
    id: 5,
    completed: true,
    title:
      "“CAMP_5” campaign is performing with ROAS 0.2. Turn on XENA optimistion for “CAMP_1” campaign to improve ROAS.",
  },
];

function XenaNavigator() {
  const [checklistItems, setChecklistItems] = useState(items);

  const onHandleCheck = (item) => {
    const index = checklistItems.findIndex((el) => el.id === item.id);
    console.log(index);
    const tempList = checklistItems;
    console.log(checklistItems[index]);
    tempList[index] = { ...item, completed: !item.completed };
    console.log(tempList[index]);
    // setChecklistItems([]);
    setChecklistItems([...tempList]);
  };

  return (
    <LayoutGroup>
      <motion.div
        variants={cardAnimation}
        animate="animation"
        initial="hidden"
        className="flex flex-col gap-2 1min-h-[200px] order-8 md:order-9  col-span-12 sm:col-span-7 lg:col-span-4 xl:col-span-6 row-span-2  rounded-2xl"
      >
        <p className="text-[12px] 2xl:text-base font-bold">XENA Insights</p>
        <div className="flex flex-col p-2 xl:p-5 pb-0 h-full bg-[#F4F6FA] rounded-xl gap-2">
          <CircleProgress />
          <div className="h-full flex flex-col overflow-y-auto gap-3 max-h-[50vh]">
            {checklistItems.map((item, id) => {
              return (
                <CheckListItem
                  onHandleCheck={onHandleCheck}
                  key={id}
                  item={item}
                />
              );
            })}
          </div>
        </div>
      </motion.div>
    </LayoutGroup>
  );
}

export default XenaNavigator;
