import React from 'react';
import {  motion } from "framer-motion";
import { GridSectionHeader } from './GridSectionHeader';

function GridSection(props) {
    const gridAnimation = {
      hidden: { 
        opacity: 0,
        transition: {
          staggerChildren: 7,
          // delayChildren: 10
        }
      },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 7,
          // delayChildren: 10
        }
      }
    }
    return (
      <section className="flex flex-col w-full max-w-[1800px]">
        <GridSectionHeader headerName={props.headerName} />
        <motion.div 
            variants={gridAnimation}
            initial="hidden"
            animate="show"
            className="m-5 xl:m-6 grid grid-cols-12 gap-4 grid-flow-row auto-rows-max"
        >
          {props.children}
        </motion.div>
      </section>
    );
}

export default GridSection;