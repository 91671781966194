import { useReducer, useState } from "react";

import spActivelast12Months from "../data/campaigns/sb-active-lastmonth.json";
import spActivelast30days from "../data/campaigns/sp-active-lastmonth.json";
import spActivelast14days from "../data/campaigns/sp-active-14days.json";
import spActivelast7days from "../data/campaigns/sp-active-last7days.json";
import spPausedlast12Months from "../data/campaigns/sp-paused-lastyear.json";
import spPausedlast30days from "../data/campaigns/sp-paused-lastyear.json";
import spPausedlast14days from "../data/campaigns/sp-paused-lastyear.json";
import spPausedlast7days from "../data/campaigns/sp-paused-lastyear.json";

import sbActivelast12Months from "../data/campaigns/sb-active-lastyear.json";
import sbActivelast30days from "../data/campaigns/sb-active-lastmonth.json";
import sbActivelast14days from "../data/campaigns/sb-active-14days.json";
import sbActivelast7days from "../data/campaigns/sb-active-last7days.json";
import sbPausedlast12Months from "../data/campaigns/sb-paused-lastyear.json";
import sbPausedlast30days from "../data/campaigns/sb-paused-lastyear.json";
import sbPausedlast14days from "../data/campaigns/sb-paused-lastyear.json";
import sbPausedlast7days from "../data/campaigns/sb-paused-lastyear.json";

import sdActivelast12Months from "../data/campaigns/sd-active-lastyear.json";
import sdActivelast30days from "../data/campaigns/sd-active-lastmonth.json";
import sdActivelast14days from "../data/campaigns/sd-active-14days.json";
import sdActivelast7days from "../data/campaigns/sd-active-last7days.json";
import sdPausedlast12Months from "../data/campaigns/sd-paused-lastyear.json";
import sdPausedlast30days from "../data/campaigns/sd-paused-lastyear.json";
import sdPausedlast14days from "../data/campaigns/sd-paused-lastyear.json";
import sdPausedlast7days from "../data/campaigns/sd-paused-lastyear.json";

const useCampaignsData = () => {
  const DATA_REDUCER_ACTIONS = {
    GET_SP_ACTIVE_LAST_12_MONTHS: "get-sp-active-last-12-months",
    GET_SP_ACTIVE_LAST_30_DAYS: "get-sp-active-last-30-days",
    GET_SP_ACTIVE_LAST_14_DAYS: "get-sp-active-last-14-days",
    GET_SP_ACTIVE_LAST_7_DAYS: "get-sp-active-last-7-days",
    GET_SP_PAUSED_LAST_12_MONTHS: "get-sp-paused-last-12-months",
    GET_SP_PAUSED_LAST_30_DAYS: "get-sp-paused-last-30-days",
    GET_SP_PAUSED_LAST_14_DAYS: "get-sp-paused-last-14-days",
    GET_SP_PAUSED_LAST_7_DAYS: "get-sp-paused-last-7-days",

    GET_SB_ACTIVE_LAST_12_MONTHS: "get-sb-active-last-12-months",
    GET_SB_ACTIVE_LAST_30_DAYS: "get-sb-active-last-30-days",
    GET_SB_ACTIVE_LAST_14_DAYS: "get-sb-active-last-14-days",
    GET_SB_ACTIVE_LAST_7_DAYS: "get-sb-active-last-7-days",
    GET_SB_PAUSED_LAST_12_MONTHS: "get-sb-paused-last-12-months",
    GET_SB_PAUSED_LAST_30_DAYS: "get-sb-paused-last-30-days",
    GET_SB_PAUSED_LAST_14_DAYS: "get-sb-paused-last-14-days",
    GET_SB_PAUSED_LAST_7_DAYS: "get-sb-paused-last-7-days",

    GET_SD_ACTIVE_LAST_12_MONTHS: "get-sd-active-last-12-months",
    GET_SD_ACTIVE_LAST_30_DAYS: "get-sd-active-last-30-days",
    GET_SD_ACTIVE_LAST_14_DAYS: "get-sd-active-last-14-days",
    GET_SD_ACTIVE_LAST_7_DAYS: "get-sd-active-last-7-days",
    GET_SD_PAUSED_LAST_12_MONTHS: "get-sd-paused-last-12-months",
    GET_SD_PAUSED_LAST_30_DAYS: "get-sd-paused-last-30-days",
    GET_SD_PAUSED_LAST_14_DAYS: "get-sd-paused-last-14-days",
    GET_SD_PAUSED_LAST_7_DAYS: "get-sd-paused-last-7-days",
  };

  const datesRange = [
    { name: "Last Year", selector: "LAST_12_MONTHS" },
    { name: "Last Month", selector: "LAST_30_DAYS" },
    { name: "Last 14 days", selector: "LAST_14_DAYS" },
    { name: "Last 7 days", selector: "LAST_7_DAYS" },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState(datesRange[2]);

  const initialcampaignsData = {
    isLoading: true,
    data: spActivelast14days,
  };

  const dataReducer = (state, action) => {
    switch (action.type) {
      case DATA_REDUCER_ACTIONS.GET_SP_ACTIVE_LAST_12_MONTHS: {
        return { ...state, data: spActivelast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_ACTIVE_LAST_30_DAYS: {
        return { ...state, data: spActivelast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_ACTIVE_LAST_14_DAYS: {
        return { ...state, data: spActivelast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_ACTIVE_LAST_7_DAYS: {
        return { ...state, data: spActivelast7days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_PAUSED_LAST_12_MONTHS: {
        return { ...state, data: spPausedlast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_PAUSED_LAST_30_DAYS: {
        return { ...state, data: spPausedlast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_PAUSED_LAST_14_DAYS: {
        return { ...state, data: spPausedlast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SP_PAUSED_LAST_7_DAYS: {
        return { ...state, data: spPausedlast7days, isLoading: false };
      }

      case DATA_REDUCER_ACTIONS.GET_SB_ACTIVE_LAST_12_MONTHS: {
        return { ...state, data: sbActivelast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_ACTIVE_LAST_30_DAYS: {
        return { ...state, data: sbActivelast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_ACTIVE_LAST_14_DAYS: {
        return { ...state, data: sbActivelast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_ACTIVE_LAST_7_DAYS: {
        return { ...state, data: sbActivelast7days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_PAUSED_LAST_12_MONTHS: {
        return { ...state, data: sbPausedlast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_PAUSED_LAST_30_DAYS: {
        return { ...state, data: sbPausedlast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_PAUSED_LAST_14_DAYS: {
        return { ...state, data: sbPausedlast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SB_PAUSED_LAST_7_DAYS: {
        return { ...state, data: sbPausedlast7days, isLoading: false };
      }

      case DATA_REDUCER_ACTIONS.GET_SD_ACTIVE_LAST_12_MONTHS: {
        return { ...state, data: sdActivelast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_ACTIVE_LAST_30_DAYS: {
        return { ...state, data: sdActivelast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_ACTIVE_LAST_14_DAYS: {
        return { ...state, data: sdActivelast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_ACTIVE_LAST_7_DAYS: {
        return { ...state, data: sdActivelast7days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_PAUSED_LAST_12_MONTHS: {
        return { ...state, data: sdPausedlast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_PAUSED_LAST_30_DAYS: {
        return { ...state, data: sdPausedlast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_PAUSED_LAST_14_DAYS: {
        return { ...state, data: sdPausedlast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_SD_PAUSED_LAST_7_DAYS: {
        return { ...state, data: sdPausedlast7days, isLoading: false };
      }
      default:
        throw new Error(
          `Not a valid action given for campaign Reducer. Got ${action.type}`
        );
    }
  };

  const [campaignsData, campaignsDataDispatch] = useReducer(
    dataReducer,
    initialcampaignsData
  );

  // useEffect(() => {
  //   campaignsDataDispatch({ type: selectedDateRange.actionType });
  // }, [selectedDateRange]);

  return {
    campaignsData,
    campaignsDataDispatch,
    DATA_REDUCER_ACTIONS,
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
  };
};

export default useCampaignsData;
