import { Switch } from "@headlessui/react";
import { useState } from "react";

const styles = {
  enabled: "bg-green-300 text-green-700",
  paused: "bg-yellow-200 text-yellow-800",
  archived: "bg-gray-200 text-black",
};

export const StateIndicator = ({ product }) => {
  const [keywordState, setKeywordState] = useState(product["state"]);

  const handleOnchange = (e) => {
    console.log(e);
    setKeywordState(keywordState === "enabled" ? "disabled" : "enabled");
  };

  return (
    <div
      className={`flex justify-between items-center text-xs px-2 py-1 rounded-xl text-center capitalize font-bold ${styles[keywordState]}`}
    >
      <>
        <p className="w-full">{keywordState}</p>
        {keywordState !== "archived" && (
          <Switch
            disabled={keywordState === "archived"}
            checked={keywordState === "enabled"}
            onChange={handleOnchange}
            className={`${
              keywordState === "enabled" ? "bg-teal-900" : "bg-gray-700"
            }
          disabled:cursor-not-allowed disabled:opacity-25 relative inline-flex h-[23px] w-[42px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${
                keywordState === "enabled"
                  ? "translate-x-full"
                  : "translate-x-0"
              }
            pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        )}
      </>
    </div>
  );
};
