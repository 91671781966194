import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";
import { CardHeader } from "../Common/CardHeader";
import { LayoutGroup, motion } from "framer-motion";
import { AnimatedCounter } from "../Common/AnimatedCounter";
import { ExpandedCard } from "./ExpandedCard";
import { LineChart } from "./LineChart";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

function SalesCard({ dashData }) {
  let [isExpanded, setIsExpanded] = useState(false);
  const cardAnimation = {
    animation: {
      opacity: 1,
      y: 0,
      transition: {
        // staggerChildren: .1,
        type: "spring",
        bounce: 0,
      },
    },
    hover: { scale: 1.1 },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        // staggerChildren: .1,
        type: "spring",
        bounce: 0,
      },
    },
  };
  return (
    <>
      <LayoutGroup>
        <motion.div
          variants={cardAnimation}
          animate="animation"
          initial="hidden"
          whileHover="hover"
          layout
          layoutId="sales-card"
          className="flex flex-col justify-between hover:shadow-xl shadow-[#DEF5ED] order-1 col-span-6  lg:col-span-3 row-span-2 bg-[#DEF5ED] rounded-2xl overflow-hidden"
          onClick={() => {
            setIsExpanded(true);
          }}
        >
          <div className="p-2 xl:p-5 pb-0 cursor-pointer ">
            <CardHeader
              headerName="Total Sales"
              trendPercentage={dashData.data.salesChange}
              isTrendUp={dashData.data.salesTrendUp}
            />
            <AnimatedCounter
              from={0}
              prefix="$"
              to={dashData.data.sales}
              className="text-3xl 2xl:text-6xl my-2 2xl:my-4 font-Raleway"
            />
          </div>
          <LineChart dashData={dashData} />
        </motion.div>
        {isExpanded && <ExpandedCard setIsExpanded={setIsExpanded} />}
      </LayoutGroup>
    </>
  );
}

export default SalesCard;
