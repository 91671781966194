import { SearchIcon } from "@heroicons/react/solid";
import React, { useRef } from "react";

export const SearchInput = ({ setSearchText }) => {
  const inputRef = useRef();
  const handleSearchClick = () => {
    setSearchText(inputRef.current.value);
  };
  return (
    <div className="flex items-center w-full sm:w-auto">
      <label htmlFor="campaign-search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <SearchIcon className="w-5 h-5" />
        </div>
        <input
          ref={inputRef}
          type="text"
          name="campaign-search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 p-2.5"
          placeholder="Search"
          required=""
        />
      </div>
      <button
        onClick={handleSearchClick}
        className="p-2.5 ml-2 text-sm font-medium text-white bg-pink-500 rounded-lg hover:bg-pink-600 focus:ring-4 focus:outline-none focus:ring-pink-300"
      >
        <SearchIcon className="w-5 h-5" />
        <span className="sr-only">Search</span>
      </button>
    </div>
  );
};
