import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

export function LineChartExpanded({ dashData }) {
  function createGradient(ctx, area) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0, "#B6EDFC");
    gradient.addColorStop(1, "#00C8FF");
    return gradient;
  }  

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        bodyFont: {
          family: "Poppins"
        },
        titleFont: {
          family: "Poppins"
        },
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        display: false,
        ticks: {
          display: false,
          padding: 0,
          maxRotation: 0,
          // beginAtZero: true
          // suggestedMin: 100000,
        },
      },
      x: {
        display: false,
        ticks: {
          display: false,
          padding: -50,
          maxRotation: 0,
          maxTicksLimit: 20,
          // sampleSize: 10,
          beginAtZero: true
        },
      },
    },
    layout: {
      padding: 0,
    },
  };

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart && dashData?.data) {
      return;
    }

    const labels = dashData?.data?.cardChartLabels;
    const data = {
      labels,
      datasets: [
        {
          label: "Sales",
          data: dashData?.data?.spendGraphData,
          borderColor: "rgb(0, 0, 0)",
          borderWidth: 1,
          fill: true,
          cubicInterpolationMode: "monotone",
          pointRadius: 1.5,
        },
      ],
    };


    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };
    setChartData(chartData);
  }, [dashData]);

  return (
    <div className="w-full p-0 m-0 h-[50vh] scale-[104%] cursor-pointer transform translate-y-2">
      <Line ref={chartRef} options={options} data={chartData} />
    </div>
  );
}
