import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Filler,
} from "chart.js";
// import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, LineElement, Filler);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      displayColors: false,
    },
  },
  scales: {
    y: {
      beginAtZero: false,
      display: false,
      ticks: {
        display: false,
        tickMarkLength: 0,
      },
    },
    x: {
      beginAtZero: false,
      display: false,
      ticks: {
        display: false,
      },
    },
  },
};

export function LineChart({ points, id }) {
  const chartRef = useRef(null);
  const [, /*chartData*/ setChartData] = useState({ datasets: [] });
  const [weeklySalesData /*setWeeklySalesData*/] = useState(points);
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart && weeklySalesData?.length) {
      return;
    }
    const datapoints = weeklySalesData;

    const labels = datapoints?.reduce((prev, curr) => {
      return [...prev, curr];
    }, []);
    const data = {
      labels,
      datasets: [
        {
          label: "Sales",
          data: datapoints?.reduce((prev, curr) => {
            return [...prev, curr];
          }, []),
          borderWidth: 0,
          fill: true,
          backgroundColor: "#3CD0F9",
          cubicInterpolationMode: "monotone",
          pointRadius: 0,
        },
      ],
    };

    setChartData(data);
  }, [weeklySalesData]);
  // return <Line ref={chartRef} options={options} data={chartData} />;
  return {
    1: (
      <svg
        className="w-full"
        viewBox="0 0 79 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.998 8.12958L0.998047 0V37.1042H78.998V8.12958Z"
          fill="#3CD0F9"
        />
        <path d="M79 65.9161L1 74V37.1042H79V65.9161Z" fill="#3CD0F9" />
      </svg>
    ),
    2: (
      <svg
        className="w-full"
        viewBox="0 0 79 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.998 16.0137L0.998047 0.379883V28.1038H78.998V16.0137Z"
          fill="#8BE4FC"
        />
        <path d="M79 39.9179L1 55.4639V27.8957H79V39.9179Z" fill="#8BE4FC" />
      </svg>
    ),
    3: (
      <svg
        className="w-full"
        viewBox="0 0 79 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.998 0.430664H0.998047V12.1039H78.998V0.430664Z"
          fill="#9CE8FD"
        />
        <path d="M79.001 22L1.00098 23.1523V11.896H79.001V22Z" fill="#9CE8FD" />
      </svg>
    ),
  }[id];
}

export const HorizontalFunnelChart = ({ points, id }) => {
  return (
    <div className="flex w-full h-full items-center">
      <LineChart id={id} points={points} />
    </div>
  );
};
