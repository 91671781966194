import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { motion } from "framer-motion";
import xenaAiChipBranding from "../../assets/img/xena-ai-chip-branding.svg";
import { VoiceActiveWave } from "./VoiceActiveWave";
import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { MicrophoneIcon } from "@heroicons/react/outline";

const XenaVoiceAI = ({ setShowXenaVoiceAI }) => {
  const [message, setMessage] = useState("");
  const commands = [
    // {
    //   command: [
    //     "What is my sales?",
    //     "What is my sales last week?",
    //     "What was the total revenue generated yesterday?",
    //     "What was the total amount of sales from the last day?",
    //     "How much money did we make in sales for the previous day?",
    //   ],
    //   callback: () => {
    // const answers = [
    //   "The overall sales for the last day is $11,370,49.",
    //   "Yesterday's total sales amounted to $11,370,49.",
    //   "We made $11,370,49 in sales for the previous day.",
    //   "Our overall sales for the last 24 hours was $11,370,49.",
    //   "The total revenue generated yesterday was $11,370,49.",
    // ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    {
      command: [
        "What is my sales?",
        "sales last 7 days",
        "sales last week",
        "What is my sales last week?",
        "last week sales",
        "last 7 days sales",
        "What is my sales last 7 days?",
      ],
      callback: () => {
        const answers = [
          "The overall sales for the last 7 days is $11,370,49.",
          "Sales for the last 7 days amounted to $11,370,49.",
          "We made $11,370,49 in sales for the previous week.",
          "Our overall sales for the last week was $11,370,49.",
          "The total revenue generated this week was $11,370,49.",
        ];
        setMessage(answers[Math.floor(Math.random() * answers.length)]);
      },
      isFuzzyMatch: true,
      bestMatchOnly: true,
      fuzzyMatchingThreshold: 0.5,
    },

    {
      command: [
        "how much is my orders",
        "orders last month",
        "orders last 30 days",
        "What was the total number of orders for last month?",
        "Can you tell me the overall order count for the previous month?",
        "How many orders did we have last month?",
        "What was our order volume like for the last month?",
      ],
      callback: () => {
        const answers = [
          "The overall order count for last month is 708.",
          "Last month, we had a total of 708 orders.",
          "Our order volume for the previous month was 708.",
          "We processed 708 orders in the last month.",
        ];
        setMessage(answers[Math.floor(Math.random() * answers.length)]);
      },
      isFuzzyMatch: true,
      bestMatchOnly: true,
      fuzzyMatchingThreshold: 0.5,
    },
    {
      command: [
        "top kewords",
        "What are the top keywords for the palm leaf plate product?",
        "Which keywords are most popular for the palm leaf plate?",
        "Can you tell me the most searched keywords for the palm leaf plate?",
        "What are the main keywords for the palm leaf plate product?",
      ],
      callback: () => {
        const answers = [
          "Here are the top keywords: disposable palm leaf plate, disposable plates, biodegradable bamboo plate.",
          "The most popular keywords for the palm leaf plate product are: disposable palm leaf plate, disposable plates, biodegradable bamboo plate.",
          "Based on our analysis, the top searched keywords for the palm leaf plate product are: disposable palm leaf plate, disposable plates, biodegradable bamboo plate.",
          "Our research indicates that the main keywords for the palm leaf plate product are: disposable palm leaf plate, disposable plates, biodegradable bamboo plate.",
        ];
        setMessage(answers[Math.floor(Math.random() * answers.length)]);
      },
      isFuzzyMatch: true,
      bestMatchOnly: true,
      fuzzyMatchingThreshold: 0.5,
    },

    // {
    //   command: [
    //     "Who are my top competitors for bamboo plates?",
    //     "Which companies are my main competitors for bamboo plates?",
    //     "Who are the biggest competitors for my bamboo plate product?",
    //   ],
    //   callback: () => {
    //     const answers = [
    //       "Here are the top competitors: Chicleaf, Kijani, Charity leaf.",
    //       "Your top competitors for bamboo plates are Chicleaf, Kijani, and Charity leaf.",
    //       "Chicleaf, Kijani, and Charity leaf are the top competitors for your bamboo plate product.",
    //     ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    // {
    //   command: [
    //     "What was the ROAS for yesterday?",
    //     "Can you tell me the ROAS for the last 24 hours?",
    //     "What was the return on ad spend for the previous day?",
    //   ],
    //   callback: () => {
    //     const answers = [
    //       "The ROAS for the last day is 2.1.",
    //       "Yesterday's ROAS was 2.1.",
    //       "The return on ad spend for the previous day was 2.1.",
    //     ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    // {
    //   command: [
    //     "What caused the spike in spending?",
    //     "Why did the spend increase so much?",
    //     "Can you explain the reason for the high spending?",
    //   ],
    //   callback: () => {
    //     const answers = [
    //       "The reason might be of the following: Average CPC increased by 20%, Budget increased by $20, 5 new keywords added.",
    //       "The spend increased due to an increase in the average CPC, a higher budget, and the addition of new keywords.",
    //       "Several factors contributed to the high spending, including a rise in the average CPC, an increase in the budget, and the addition of new keywords.",
    //     ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    // {
    //   command: [
    //     "What can we do to reduce the spending?",
    //     "How can we lower the cost per click?",
    //     "Are there any ways to decrease the budget?",
    //   ],
    //   callback: () => {
    //     const answers = [
    //       "We could consider reducing the bids on certain keywords or pausing low-performing ads.",
    //       "To lower the cost per click, we may need to optimize our ad targeting or adjust our bidding strategy.",
    //       "We could explore ways to decrease the budget, such as by reducing our ad frequency or targeting a narrower audience.",
    //     ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    // {
    //   command: [
    //     "What's causing the decrease in sales?",
    //     "Why are our sales declining?",
    //     "What factors are contributing to the low sales?",
    //   ],
    //   callback: () => {
    //     const answers = [
    //       "Organic sales declined by 10%, 2 products are ineligible, and 10 keywords have more than 100% ACOS.",
    //       "The sales may be low due to a decline in organic sales, 2 products becoming ineligible, and 10 keywords with more than 100% ACOS.",
    //       "A possible reason for the low sales is that organic sales have decreased by 10%, 2 products are now ineligible, and 10 keywords have more than 100% ACOS.",
    //     ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    // {
    //   command: [
    //     "What did Xena optimize today?",
    //     "what optimisations",
    //     "optimisation",
    //     "Xena Optimized",
    //     "cena Optimized",
    //     "what did cena optimize",
    //     "optimise",
    //     "optimisations",
    //   ],
    //   callback: () =>
    //     setMessage(
    //       `Xena Optimised a campaign 'AUTO_KIT_SP' by increasing budget by 10% to $544.`
    //     ),
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    //   fuzzyMatchingThreshold: 0.5,
    // },
    // {
    //   command: ["Hello", "Hi", "hay", "hi", "hai"],
    //   callback: () => setMessage(`Hi there! How can I help you?`),
    //   isFuzzyMatch: true,
    //   bestMatchOnly: true,
    // },
    // {
    //   command: ["sales last week", "sales", "last week", "what is sales"],
    //   callback: () => setMessage(`Your sales from last 7 days is $96,246.`),
    //   isFuzzyMatch: true,
    //   fuzzyMatchingThreshold: 0.5,
    //   bestMatchOnly: true,
    // },
    // {
    //   command: [
    //     "what is my top selling product?",
    //     "Which product is selling the most right now?",
    //     "What's my best-selling product this month?",
    //     "Can you tell me which item is performing the best in terms of sales?",
    //     "Which product has the highest sales figures for this period?",
    //     "What's the most popular product among my customers at the moment?",
    //     "Which product has been generating the most revenue lately?",
    //     "Which item has the highest sales volume currently?",
    //     "What's the top-performing product in terms of sales?",
    //     "Which product has been consistently selling well?",
    //     "What's the current bestseller in my store?",
    //   ],
    //   callback: () => {
    //     const answers = [
    //       "Based on the sales data for this month, BCS175 is currently your top selling product.",
    //       "Your top selling product for this month is BCS175.",
    //       "Out of all your products, BCS175 has been the most popular this month and is currently your top seller.",
    //       "According to the sales records, BCS175 is currently the product that has sold the most for you this month.",
    //       "The data shows that your top performing product for this month is BCS175.",
    //     ];
    //     setMessage(answers[Math.floor(Math.random() * answers.length)]);
    //   },
    //   isFuzzyMatch: true,
    //   fuzzyMatchingThreshold: 0.5,
    //   bestMatchOnly: true,
    // },
    {
      command: "clear",
      callback: ({ resetTranscript }) => resetTranscript(),
    },
  ];

  const {
    finalTranscript,
    listening,
    browserSupportsSpeechRecognition,
    interimTranscript,
    isMicrophoneAvailable,
  } = useSpeechRecognition({ commands });

  let VoiceAIAnimation = {
    initial: { opacity: 0, x: 500 },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        mass: 0.8,
        damping: 12,
      },
    },
  };

  useEffect(() => {
    SpeechRecognition.startListening();

    return () => {
      SpeechRecognition.stopListening();
    };
  }, []);

  useEffect(() => {
    if (listening) {
      setMessage("");
    }
  }, [listening]);

  if (!browserSupportsSpeechRecognition) {
    return (
      <motion.div
        initial="initial"
        animate="animate"
        variants={VoiceAIAnimation}
        className="p-5 min-w-[250px] max-w-[350px] w-11/12 md:w-[30vw] fixed bottom-10 md:top-10 left-0 md:right-5 shadow-2xl rounded-2xl z-[99] border-[1px] border-grey-100 bg-white"
      >
        <div className="flex justify-between">
          <img className="w-2>4 mb-5" src={xenaAiChipBranding} alt="" />
          <XIcon
            onClick={() => {
              setShowXenaVoiceAI(false);
            }}
            className="w-5 h-5 text-black"
          />
        </div>
        <p className="text-left">Browser doesn't support speech recognition.</p>
      </motion.div>
    );
  }

  if (!isMicrophoneAvailable) {
    return (
      <motion.div
        initial="initial"
        animate="animate"
        variants={VoiceAIAnimation}
        className="p-5 min-w-[250px] max-w-[350px] w-[30vw] fixed top-10 right-5 shadow-2xl rounded-2xl z-50 border-[1px] border-grey-100 bg-white"
      >
        <div className="flex justify-between">
          <img className="w-2>4 mb-5" src={xenaAiChipBranding} alt="" />
          <button>
            <XIcon
              onClick={() => {
                setShowXenaVoiceAI(false);
              }}
              className="w-5 h-5 text-black"
            />
          </button>
        </div>
        <p className="text-left">Microphone not available.</p>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="initial"
      variants={VoiceAIAnimation}
      className="p-5 min-w-[250px] max-w-[350px] w-[30vw] fixed top-10 right-5 shadow-2xl rounded-2xl z-[999999] border-[1px] border-grey-100 bg-white"
    >
      <div className="flex justify-between items-center  mb-5">
        <img className="w-24" src={xenaAiChipBranding} alt="" />
        <XIcon
          onClick={() => {
            setShowXenaVoiceAI(false);
          }}
          className="w-5 h-5 text-black"
        />
      </div>
      <p className="text-left">
        {finalTranscript}
        <span className="opacity-40">{interimTranscript}</span>
      </p>
      {message && (
        <p className="bg-white p-4 rounded-xl mt-5 text-left border border-gray-400">
          {message}
        </p>
      )}

      <>
        {listening ? (
          <VoiceActiveWave />
        ) : (
          <div className="flex w-full mt-4">
            <button
              onClick={SpeechRecognition.startListening}
              className="p-2 bg-pink-500 rounded-full w-12 h-12 mx-auto animate-pulse"
            >
              <MicrophoneIcon className="text-white w-full" />
            </button>
          </div>
        )}
      </>
    </motion.div>
  );
};
export default XenaVoiceAI;
