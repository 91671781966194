import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import useCampaignsData from "../../hooks/useCampainsData";

import SidebarDashGridLayout from "../../layouts/SidebarDashGridLayout";
import { CampaignTypeFilter } from "./CampaignTypeFilter";
import ExpandedView from "./ExpandedView";
import { FilterSection } from "./FilterSection";
import { Pagination } from "./Pagination";
import { TableColumn } from "./TableColumn";
import { TableRow } from "./TableRow";
import { XenaOptimisedStateIndicator } from "./XenaOptimisedStateIndicator";

const TableHeaders = [
  {
    name: (
      <p className="px-1 py-0.5 bg-pink-500 rounded text-white text-xs">
        Xena Optimized
      </p>
    ),
    columnName: "xenaOptimisedState",
    orderDisabled: true,
    initialWidth: 140,
  },
  {
    name: "Name",
    columnName: "campaignName",
    isResizable: true,
    initialWidth: 300,
    orderDisabled: true,
  },
  {
    name: "Ad Sales",
    columnName: "adsales",
    isCurrency: true,
    orderDisabled: false,
  },
  {
    name: "Spend",
    columnName: "spend",
    isCurrency: true,
    orderDisabled: false,
  },
  { name: "ROAS", columnName: "roas", orderDisabled: false },
  { name: "ACOS", columnName: "acos", suffix: "%", orderDisabled: false },
  {
    name: "Clicks",
    columnName: "clicks",
    isNumber: true,
    orderDisabled: false,
  },
  {
    name: "Impressions",
    columnName: "impressions",
    isNumber: true,
    orderDisabled: false,
  },
  {
    name: "Conv. Rate",
    columnName: "conversionRate",
    suffix: "%",
    orderDisabled: false,
  },
  { name: "CPC", columnName: "cpc", prefix: "$", orderDisabled: false },
  { name: "CTR", columnName: "ctr", suffix: "%", orderDisabled: false },
  {
    name: "Orders",
    columnName: "orders",
    isNumber: true,
    isShown: true,
    orderDisabled: false,
  },
  {
    name: "Ordered Units",
    columnName: "orderedUnits",
    isNumber: true,
    orderDisabled: false,
  },
];

const CampaignPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
    campaignsData,
    campaignsDataDispatch,
    DATA_REDUCER_ACTIONS,
  } = useCampaignsData();
  const reducerActions = useMemo(
    () => DATA_REDUCER_ACTIONS, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [currentSort, setCurrentSort] = useState("adsales");
  const [campaignType, setCampaignType] = useState("sp");
  const [viewDetail, setViewDetail] = useState();

  const stateOptions = [
    // {
    //   name: "All Campaigns",
    //   state: "",
    // },
    {
      name: "Active Campaigns",
      state: "active",
    },
    {
      name: "Paused Campaigns",
      state: "paused",
    },
  ];

  const [stateFilter, setStateFilter] = useState(stateOptions[1]);
  const [, /*searchText*/ setSearchText] = useState("");
  const [currentSortOrder, setCurrentSortOrder] = useState("desc");

  const [selectedHeaders, setSelectedHeaders] = useState(
    TableHeaders.slice(0, 6)
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const togleSelection = (name, checked) => {
    // console.log(name, checked);

    if (!checked) {
      const found = selectedHeaders.some((el) => el.name === name);
      if (found) {
        let changedHeader = selectedHeaders.filter((selectedHeaders) => {
          return selectedHeaders.name !== name;
        });
        setSelectedHeaders(changedHeader);
      }
      // let index = selectedHeaders.findIndex((a) => a.name === name);
      // if (found) {
      //   selectedHeaders.splice(
      //     selectedHeaders.findIndex((a) => a.name === name),
      //     1
      //   );
      // }
      console.log(selectedHeaders);
    } else {
      const found = selectedHeaders.some((el) => el.name === name);
      if (!found) {
        const header = TableHeaders.filter((TableHeaders) => {
          return TableHeaders.name === name;
        });
        setSelectedHeaders((prev) => [...prev, header[0]]);
        console.log("saved");
      }
    }
  };

  const handleHeaderSortClick = (toSort) => {
    if (currentSort === toSort)
      setCurrentSortOrder(currentSortOrder === "asc" ? "desc" : "asc");
    else {
      setCurrentSort(toSort);
      setCurrentSortOrder("asc");
    }
  };

  const generateValue = (
    campaign,
    header,
    isXenaOptimised,
    setIsXenaOptimised
  ) => {
    const prefix = header?.prefix || "";
    const suffix = header?.suffix || "";
    if (header.columnName === "xenaOptimisedState") {
      return (
        <XenaOptimisedStateIndicator
          isXenaOptimised={isXenaOptimised}
          setIsXenaOptimised={setIsXenaOptimised}
          campaign={campaign}
        />
      );
    }
    if (
      campaign[header.columnName] === null ||
      campaign[header.columnName] === undefined
    ) {
      return "--";
    } else {
      if (header.columnName === "campaignName") {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              setIsExpanded(true);
              setViewDetail(campaign);
            }}
          >
            <div className="p-2 rounded-lg bg-[#4496E3] font-medium text-white">
              {campaignType.toLocaleUpperCase()}
            </div>
            <p className="font-medium text-black underline flex">
              {campaign[header.columnName]}
              <ExternalLinkIcon className="w-4 h-4 text-black ml-1" />
            </p>
          </div>
        );
      } else if (header.isCurrency) {
        return (
          prefix +
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(campaign[header.columnName]) +
          suffix
        );
      } else if (header.isNumber) {
        return (
          prefix +
          new Intl.NumberFormat("en-US").format(campaign[header.columnName]) +
          suffix
        );
      } else {
        return prefix + campaign[header.columnName] + suffix;
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    setIsLoading(false);
    const dispatchCommand = `GET_${campaignType.toUpperCase()}_${
      stateFilter.state.toUpperCase() || "enabled"
    }_${selectedDateRange.selector}`;
    campaignsDataDispatch({
      type: reducerActions[dispatchCommand],
    });

    return () => {
      controller.abort();
    };
  }, [
    reducerActions,
    campaignType,
    campaignsDataDispatch,
    selectedDateRange.selector,
    stateFilter,
  ]);

  return (
    <SidebarDashGridLayout headerName="Campaigns">
      <CampaignTypeFilter
        campaignType={campaignType}
        setCampaignType={setCampaignType}
      />
      <div className="col-span-12 flex flex-col h-full bg-[#F7F7F7] border-2 border-[#C4C4C4] rounded-lg p-3">
        <FilterSection
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          stateOptions={stateOptions}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          setSearchText={setSearchText}
          datesRange={datesRange}
          campaignData={campaignsData}
          TableHeaders={TableHeaders}
          togleSelection={togleSelection}
          selectedHeaders={selectedHeaders}
        />
        <div className="overflow-x-auto min-h-[500px] relative">
          <StyledTable className="w-full text-sm text-left text-black table-fixed">
            <thead className="text-xs">
              <tr>
                {!isLoading &&
                  selectedHeaders.map((header, id) => {
                    return (
                      <TableColumn
                        key={id}
                        isFixed={header.isFixed}
                        isResizable={header.isResizable}
                        intitialWidth={header.initialWidth}
                        onClick={() => {
                          handleHeaderSortClick(header.columnName);
                        }}
                        scope="col"
                        className="min-w-[300px] select-none"
                      >
                        <div className="flex justify-between font-medium">
                          {header.name}
                          {currentSort === header.columnName &&
                            currentSortOrder === "asc" && (
                              <div className="flex flex-col gap-0 text-[9px] leading-none">
                                <span>▲</span>
                                <span>▽</span>
                              </div>
                            )}
                          {currentSort === header.columnName &&
                            currentSortOrder === "desc" && (
                              <div className="flex flex-col gap-0 text-[9px] leading-none">
                                <span>△</span>
                                <span>▼</span>
                              </div>
                            )}
                          {currentSort !== header.columnName &&
                            (currentSortOrder === "desc" ||
                              currentSortOrder === "asc") && (
                              <div className="flex flex-col gap-0 text-[9px] leading-none">
                                <span>△</span>
                                <span>▽</span>
                              </div>
                            )}
                        </div>
                      </TableColumn>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                campaignsData?.data?.map((campaign, id) => {
                  return (
                    <TableRow
                      generateValue={generateValue}
                      selectedHeaders={selectedHeaders}
                      key={id}
                      campaign={campaign}
                    />
                  );
                })}
              {isLoading &&
                [...Array(10)].map((e, i) => {
                  return (
                    <tr key={i} className="">
                      {[...Array(selectedHeaders.length)].map((e, i) => (
                        <td key={i} className="p-3">
                          <div className="bg-gray-300 animate-pulse h-5 rounded"></div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </StyledTable>
        </div>
        {<Pagination />}
      </div>
      {isExpanded && viewDetail && (
        <ExpandedView setIsExpanded={setIsExpanded} viewDetail={viewDetail} />
      )}
    </SidebarDashGridLayout>
  );
};

export default CampaignPage;

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 1em;
  td,
  th {
    padding: 8px;
  }

  tr {
    /* border: 1px solid black; */
    margin: 10px;
  }
  tr:hover {
    background-color: #f2e2f2;
  }

  th {
    /* padding-top: 12px; */
    /* padding-bottom: 12px; */
    text-align: left;
    background-color: #e4e4e4;
    color: black;
  }
  thead {
    td,
    th {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    td,
    th {
      background: white;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
    }
    tr {
      &.xena-optimised-style {
        td {
          border-top: 2px solid #f8157f;
          border-bottom: 2px solid #f8157f;
          :first-child {
            border-radius: 10px 0 0 10px;
            border-top: 2px solid #f8157f;
            border-left: 2px solid #f8157f;
            border-bottom: 2px solid #f8157f;
          }
          :last-child {
            border-radius: 0 10px 10px 0;
            border-top: 2px solid #f8157f;
            border-right: 2px solid #f8157f;
            border-bottom: 2px solid #f8157f;
          }
        }
      }
    }
  }
`;
