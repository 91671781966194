import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { ClockIcon } from "@heroicons/react/outline";

export default function DateSelector({
  datesRange,
  selectedDateRange,
  setSelectedDateRange,
  customDateRange,
  setCustomDateRange,
}) {
  const handleChange = (newSelection) => {
    if (selectedDateRange.name !== newSelection.name) {
      setSelectedDateRange(newSelection);
    }
  };

  return (
    <>
      <div className="flex items-center relative">
        <div className="w-auto cursor-pointer">
          <Listbox value={selectedDateRange} onChange={handleChange}>
            <div className="relative">
              <Listbox.Button className="relative flex gap-1 items-center w-full rounded-md bg-white py-2 pl-2 pr-10 text-left border border-[#C4C4C4] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-xs cursor-pointer">
                <ClockIcon className="w-4 h-4 text-black" />

                <span className="block truncate text-xs ">
                  {selectedDateRange.name}
                </span>

                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute -bottom-1 left-0 bg-white border border-[#c2c2c2] rounded-lg w-max mt-1 text-sm overflow-hidden cursor-pointer translate-y-full">
                  {datesRange.map((item, itemIdx) => (
                    <Listbox.Option
                      key={itemIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 z-10 ${
                          active ? "bg-pink-100 text-pink-900" : "text-gray-900"
                        }`
                      }
                      value={item}
                    >
                      <>
                        <span
                          className={`block truncate ${
                            selectedDateRange.name === item.name
                              ? "font-medium"
                              : "font-normal"
                          }`}
                        >
                          {item.name}
                        </span>
                        {selectedDateRange.name === item.name ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    </>
  );
}
