import React from 'react'

const DashCardsLoading = () => {
  return (
    <>
        <div className="animate-pulse h-[15vh] sm:h-[30vh] flex flex-grow flex-col justify-between order-1 col-span-6 sm:col-span-3 row-span-2 bg-[#f0f0f0] rounded-2xl 2xl:rounded-[40px] overflow-hidden" />
        <div className="animate-pulse h-[15vh] sm:h-[30vh] flex flex-grow flex-col justify-between order-1 col-span-6 sm:col-span-3 row-span-2 bg-[#f0f0f0] rounded-2xl 2xl:rounded-[40px] overflow-hidden" />
        <div className="animate-pulse h-[5vh] sm:h-[100%] flex flex-grow order-3 col-span-6 sm:col-span-3 row-span-2 md:row-span-1 bg-[#f0f0f0] rounded-2xl" />
        <div className="animate-pulse h-[30vh] sm:h-[60vh] flex flex-grow col-span-12 md:col-span-3 row-span-4 order-5 md:order-4 p-3 xl:p-5 bg-[#f0f0f0] rounded-2xl" />
        <div className="animate-pulse h-[15vh] sm:h-[30vh] flex flex-grow order-6 col-span-12 sm:col-span-6 md:col-span-3 row-span-2 bg-[#f0f0f0] rounded-2xl" />
        <div className="animate-pulse h-[5vh] sm:h-[100%] flex flex-grow col-span-6 sm:col-span-3 order-4 md:order-5 row-span-2 md:row-span-1 bg-[#f0f0f0] rounded-2xl" />
        <div className="animate-pulse h-[15vh] sm:h-[30vh] flex flex-grow order-6 col-span-12 md:col-span-6 row-span-2 bg-[#f0f0f0] rounded-2xl" />
    </>
  )
}

export default DashCardsLoading;