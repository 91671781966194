import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/common/Routes/RequireAuth";
import AuthProvider from "./components/contexts/authContext";
import Dashboard from "./components/pages/Dashboard";
import SignInPage from "./components/pages/SignIn";
import PersistLogin from "./PersistLogin";
import XenaCloud from "./components/pages/XenaCloud";
import ProductsPage from "./components/pages/ProductPage";
import InventoryManagementPage from "./components/pages/InventoryManagement";
import CampaignPage from "./components/pages/CampaignPage";
import KeywordReportPage from "./components/pages/KeywordReportPage";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/xenacloud" element={<XenaCloud />} />
            <Route
              path="/inventory-management"
              element={<InventoryManagementPage />}
            />
            <Route path="/campaigns" element={<CampaignPage />} />
            <Route path="/keyword-report" element={<KeywordReportPage />} />
          </Route>
        </Route>
        <Route path="/signin" element={<SignInPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
