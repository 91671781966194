import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, LogoutIcon } from '@heroicons/react/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

function BottomLinks({ isCollapsed, setIsCollpased}) {
    let navigate = useNavigate();
    const { authDispatch, authActions } = useAuth();
    return (
        <div className='mt-auto'>
            <button onClick={() => { navigate('/signIn'); authDispatch({ type: authActions.LOGOUT }) }} className="w-full flex items-center rounded-lg text-white hover:bg-white hover:text-black  transition-colors">
                <div className='w-8 h-8 2xl:w-12 2xl:h-12'>
                    <LogoutIcon className='w-8 2xl:w-12 hover:bg-white rounded-md p-2' />
                </div>
                {
                    !isCollapsed &&
                    <p className='text-xs xl:text-sm 2xl:text-xl font-semibold ml-0 2xl:ml-3 leading-none'>Logout</p>
                }
            </button>
            <button onClick={()=>{ setIsCollpased(!isCollapsed) }} className="w-full flex items-center rounded-lg text-white mt-2 hover:bg-white hover:text-black  transition-colors">
                <div className='w-8 h-8 2xl:w-12 2xl:h-12'>
                    {
                        isCollapsed ?
                        <ChevronDoubleRightIcon className='w-8 2xl:w-12 hover:bg-white rounded-md p-2' />
                        :
                        <ChevronDoubleLeftIcon className='w-8 2xl:w-12 hover:bg-white rounded-md p-2' />
                    }
                </div>
                {
                    !isCollapsed &&
                    <p className='text-xs xl:text-sm 2xl:text-xl font-semibold ml-0 2xl:ml-3 leading-none'>Collapse</p>
                }
            </button>
        </div>
    );
}

export default BottomLinks;