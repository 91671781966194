import XenaAIChipBranding from "../../../../assets/img/xena-ai-chip-branding.svg";
export const Header = ({ apiError, isLoading, filteredData }) => {
  return (
    <div className="flex items-center justify-between w-full col-span-12 p-3 ">
      <img alt="" src={XenaAIChipBranding} className="w-5/12 max-h-12" />

      <div
        className={`w-max ${
          !apiError
            ? "bg-[#FF57A6] rounded-full"
            : "bg-white border-red-500 shadow-md rounded-md"
        } px-2 py-1 xl:py-2 xl:px-4`}
      >
        <p className="text-[8px] md:text-xs text-white max-w-[100px]">
          <span className="text-[12px] md:text-sm font-bold mr-1">
            {filteredData?.length}
          </span>
          Optimizations
        </p>
      </div>
    </div>
  );
};
