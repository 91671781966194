import React from 'react'
import SpeechRecognition from 'react-speech-recognition';
import './voice-bar.css';

export const VoiceActiveWave = () => {
  return (
    <div 
        onClick={SpeechRecognition.stopListening} 
        className="voice-bars h-[100px]"
    >
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
        <div className="voice-bar"></div>
    </div>
  )
}
