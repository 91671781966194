import { useEffect } from "react";
import { useState } from "react";
import SidebarDashGridLayout from "../../layouts/SidebarDashGridLayout";
import { Pagination } from "./Pagination";
import inventoryData from "../../data/inventoryPage/data.json";
import { ProductName } from "./ProductName";
import styled from "styled-components";
import { TableColumn } from "./TableColumn";
import { FilterSection } from "./FilterSection";

const generateValue = (product, header) => {
  const prefix = header?.prefix || "";
  const suffix = header?.suffix || "";
  if (
    product[header.columnName] === null ||
    product[header.columnName] === undefined
  ) {
    return "--";
  } else {
    if (header.columnName === "title") {
      return <ProductName product={product} />;
    } else if (header.columnName === "restockAmount") {
      return (
        <div className="flex flex-col p-2 bg-pink-500 rounded text-white">
          <p className="font-bold text-xs">Restock Amount:</p>
          <p className="text-4xl font-Raleway">{product["restockAmount"]}</p>
        </div>
      );
    } else if (header.isCurrency) {
      return (
        prefix +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(product[header.columnName]) +
        suffix
      );
    } else if (header.isNumber) {
      return (
        prefix +
        new Intl.NumberFormat("en-US").format(product[header.columnName]) +
        suffix
      );
    } else {
      return prefix + product[header.columnName] + suffix;
    }
  }
};

const TableHeaders = [
  {
    name: "Product",
    columnName: "title",
    isResizable: true,
    initialWidth: 300,
    // isFixed: true,
  },
  { name: "ASIN", columnName: "asin" },
  { name: "SKU", columnName: "sku" },
  { name: "In Stock", columnName: "instock", isNumber: true },
  { name: "Restock Qty.", columnName: "restockAmount", isNumber: true },
];

const InventoryManagementPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [restockData, setRestockData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadTime, setLeadTime] = useState(30);
  const [currentSort, setCurrentSort] = useState("restockqty");
  const [currentSortOrder, setCurrentSortOrder] = useState("desc");

  useEffect(() => {
    setRestockData([]);
    setIsLoading(true);
    setRestockData(inventoryData);
    setIsLoading(false);
  }, [leadTime, currentPage, currentSort, currentSortOrder]);

  const handleHeaderSortClick = (toSort) => {
    if (currentSort === toSort)
      setCurrentSortOrder(currentSortOrder === "asc" ? "desc" : "asc");
    else {
      setCurrentSort(toSort);
      setCurrentSortOrder("asc");
    }
  };

  return (
    <SidebarDashGridLayout headerName="Inventory Management">
      <div className="col-span-12 flex flex-col h-full bg-[#F7F7F7] border-2 border-[#C4C4C4] rounded-lg p-3">
        <FilterSection setLeadTime={setLeadTime} />
        <div className="overflow-x-auto min-h-[500px] relative">
          <StyledTable className="w-full text-sm text-left text-black table-fixed">
            <thead className="text-xs">
              <tr>
                {TableHeaders.map((header, id) => {
                  return (
                    <TableColumn
                      key={id}
                      isFixed={header.isFixed}
                      isResizable={header.isResizable}
                      intitialWidth={header.initialWidth}
                      onClick={() => {
                        handleHeaderSortClick(header.columnName);
                      }}
                      scope="col"
                      className="min-w-[300px] select-none"
                    >
                      <div className="flex justify-between font-medium">
                        {header.name}
                        {currentSort === header.columnName &&
                          currentSortOrder === "asc" && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>▲</span>
                              <span>▽</span>
                            </div>
                          )}
                        {currentSort === header.columnName &&
                          currentSortOrder === "desc" && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>△</span>
                              <span>▼</span>
                            </div>
                          )}
                        {currentSort !== header.columnName &&
                          (currentSortOrder === "desc" ||
                            currentSortOrder === "asc") && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>△</span>
                              <span>▽</span>
                            </div>
                          )}
                      </div>
                    </TableColumn>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {restockData?.map((product, id) => {
                return (
                  <tr key={id}>
                    {TableHeaders.map((header, id) => {
                      return (
                        <td key={id} className="py-4 px-3">
                          {generateValue(product, header)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </div>

        <Pagination
          isLoading={isLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          restockData={restockData}
        />
      </div>
    </SidebarDashGridLayout>
  );
};

export default InventoryManagementPage;

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 1em;
  td,
  th {
    padding: 8px;
  }

  tr {
    /* border: 1px solid black; */
    margin: 10px;
  }
  tr:hover {
    background-color: #f2e2f2;
  }

  th {
    /* padding-top: 12px; */
    /* padding-bottom: 12px; */
    text-align: left;
    background-color: #e4e4e4;
    color: black;
  }
  thead {
    td,
    th {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    td,
    th {
      background: white;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
    }
  }
`;
