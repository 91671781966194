export const ProductName = ({ product }) => {
  console.log(product);
  return (
    <div className="flex items-center gap-2" onMo>
      <img
        className="h-20 w-20  border-solid border border-gray-300 rounded-lg drop-shadow-md"
        src={`https://api.lorem.space/image/furniture?w=100&h=100&id=${parseInt(
          Math.random() * 100
        )}`}
        alt=""
      />
      <p className="text-xs ">{product.title}</p>
    </div>
  );
};
