import React from 'react';
import dashLogoTextOnly from '../../../assets/img/xena-text-only-white.svg';

function NavLogo({ isCollapsed }) {
    return (
        <div className='flex items-center'>
            <img className={`w-8/12 xl:w-6/12 ${isCollapsed && 'w-full xl:w-full'}`} src={dashLogoTextOnly} alt="" />
        </div>
    );
}

export default NavLogo;