import { useEffect, useState } from "react";
import styled from "styled-components";
import SidebarDashGridLayout from "../../layouts/SidebarDashGridLayout";
import { FilterSection } from "./FilterSection";
import { BidModificationCell } from "./BidModificationCell";
import { Pagination } from "./Pagination";
import { StateIndicator } from "./StateIndicator";
import { TableColumn } from "./TableColumn";
import useKeywordReportData from "../../hooks/useKeywordReportData";
import { useMemo } from "react";

const generateValue = (product, header) => {
  const prefix = header?.prefix || "";
  const suffix = header?.suffix || "";
  if (
    product[header.columnName] === null ||
    product[header.columnName] === undefined
  ) {
    return "--";
  } else {
    if (header.isCurrency) {
      return (
        prefix +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(product[header.columnName]) +
        suffix
      );
    } else if (header.columnName === "state") {
      return <StateIndicator product={product} />;
    } else if (header.columnName === "bid") {
      return <BidModificationCell product={product} />;
    } else if (header.isNumber) {
      return (
        prefix +
        new Intl.NumberFormat("en-US").format(product[header.columnName]) +
        suffix
      );
    } else {
      return prefix + product[header.columnName] + suffix;
    }
  }
};

const TableHeaders = [
  { name: "State", columnName: "state" },
  {
    name: "Keyword",
    columnName: "keywordText",
    initialWidth: 180,
    // isFixed: true,
    isResizable: true,
  },
  { name: "Bid", columnName: "bid" },
  { name: "Campaign Name", columnName: "campaignName" },
  { name: "Match Type", columnName: "matchType" },
  { name: "Ad Sales", columnName: "adsales", isCurrency: true },
  { name: "Clicks", columnName: "clicks", isNumber: true },
  { name: "CTR", columnName: "ctr", suffix: "%" },
  { name: "CPC", columnName: "cpc", prefix: "$" },
  { name: "Conv. Rate", columnName: "conversionRate", suffix: "%" },
  { name: "Impressions", columnName: "impressions", isNumber: true },
  { name: "Ordered Units", columnName: "orderedUnits", isNumber: true },
  { name: "Orders", columnName: "orders", isNumber: true },
  { name: "ROAS", columnName: "roas", prefix: "$" },
  { name: "ACOS", columnName: "acos", suffix: "%" },
];

const KeywordReportPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bidData, setBidData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("adSales");
  const [, /*searchText*/ setSearchText] = useState("");
  const [currentSortOrder, setCurrentSortOrder] = useState("desc");

  const {
    selectedDateRange,
    setSelectedDateRange,
    DATA_REDUCER_ACTIONS,
    keywordReportData,
    keywordReportDataDispatch,
    datesRange,
  } = useKeywordReportData();
  const reducerActions = useMemo(
    () => DATA_REDUCER_ACTIONS, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const stateOptions = [
    {
      name: "Active Keywords",
      state: "enabled",
    },
    {
      name: "Paused Keywords",
      state: "paused",
    },
    {
      name: "Archived Keywords",
      state: "archived",
    },
  ];

  const [stateFilter, setStateFilter] = useState(stateOptions[0]);

  const handleHeaderSortClick = (toSort) => {
    if (currentSort === toSort)
      setCurrentSortOrder(currentSortOrder === "asc" ? "desc" : "asc");
    else {
      setCurrentSort(toSort);
      setCurrentSortOrder("asc");
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    if (currentPage) {
      setBidData([]);
      setIsLoading(false);
      const dispatchCommand = `GET_${
        stateFilter.state.toUpperCase() || "enabled"
      }_${selectedDateRange.selector}`;
      reducerActions[dispatchCommand] &&
        keywordReportDataDispatch({
          type: reducerActions[dispatchCommand],
        });
    }
    return () => {
      controller.abort();
    };
  }, [
    currentPage,
    keywordReportDataDispatch,
    reducerActions,
    selectedDateRange.selector,
    stateFilter.state,
  ]);

  return (
    <SidebarDashGridLayout headerName="Keyword Report">
      <div className="col-span-12 flex flex-col h-full bg-[#F7F7F7] border-2 border-[#C4C4C4] rounded-lg p-3">
        <FilterSection
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          stateOptions={stateOptions}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          setSearchText={setSearchText}
          datesRange={datesRange}
        />
        <div className="overflow-x-auto min-h-[500px] relative">
          <StyledTable className="w-full text-sm text-left text-black table-fixed">
            <thead className="text-xs">
              <tr>
                {TableHeaders.map((header, id) => {
                  return (
                    <TableColumn
                      key={id}
                      isFixed={header.isFixed}
                      isResizable={header.isResizable}
                      intitialWidth={header.initialWidth}
                      onClick={() => {
                        handleHeaderSortClick(header.columnName);
                      }}
                      scope="col"
                      className="min-w-[300px] select-none"
                    >
                      <div className="flex justify-between font-medium">
                        {header.name}
                        {currentSort === header.columnName &&
                          currentSortOrder === "asc" && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>▲</span>
                              <span>▽</span>
                            </div>
                          )}
                        {currentSort === header.columnName &&
                          currentSortOrder === "desc" && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>△</span>
                              <span>▼</span>
                            </div>
                          )}
                        {currentSort !== header.columnName &&
                          (currentSortOrder === "desc" ||
                            currentSortOrder === "asc") && (
                            <div className="flex flex-col gap-0 text-[9px] leading-none">
                              <span>△</span>
                              <span>▽</span>
                            </div>
                          )}
                      </div>
                    </TableColumn>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {keywordReportData?.data?.map((product, id) => {
                return (
                  <tr key={id}>
                    {TableHeaders.map((header, id) => {
                      return (
                        <td key={id} className="py-4 px-3">
                          {generateValue(product, header)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {isLoading &&
                [...Array(10)].map((e, i) => {
                  return (
                    <tr key={i} className="">
                      {[...Array(TableHeaders.length)].map((e, i) => (
                        <td key={i} className="p-3">
                          <div className="bg-gray-300 animate-pulse h-5 rounded"></div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </StyledTable>
        </div>
        <Pagination
          isLoading={isLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          bidData={bidData}
        />
      </div>
    </SidebarDashGridLayout>
  );
};

export default KeywordReportPage;

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 1em;
  td,
  th {
    padding: 8px;
  }

  tr {
    /* border: 1px solid black; */
    margin: 10px;
  }
  tr:hover {
    background-color: #f2e2f2;
  }

  th {
    /* padding-top: 12px; */
    /* padding-bottom: 12px; */
    text-align: left;
    background-color: #e4e4e4;
    color: black;
  }
  thead {
    td,
    th {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    td,
    th {
      background: white;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      :first-child {
        border-radius: 10px 0 0 10px;
        border-top: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        border-top: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
    }
  }
`;
