import React from 'react'
import DateSelector from './DateSelector';
import MarketSelector from './MarketSelector';

export const DashHeader = ({ selectedDateRange, setSelectedDateRange, datesRange, 
  selectedMarketPlace,
  setSelectedMarketPlace,
  marketplaces
 }) => {
  
  return (
    <div className='col-span-12 flex justify-between items-center mb-4 z-40'>
      <MarketSelector 
        selected={selectedMarketPlace}
        setSelected={setSelectedMarketPlace}
        marketplaces={marketplaces}
      />
      <DateSelector 
        selected={selectedDateRange}
        setSelected={setSelectedDateRange}
        datesRange={datesRange}
        
      />
    </div>
  )
}
