import { Tab } from "@headlessui/react";
import { CubeIcon, FireIcon, PlayIcon } from "@heroicons/react/solid";

const tabStyle = (selected) =>
  `flex flex-col md:flex-row gap-1 w-full justify-center items-center  p-2 text-xs sm:text-md lg:text-lg ${
    selected && "border-b-4 border-pink-400"
  }`;
const IconStyle = "w-6 h-6 text-black";

const tabs = [
  {
    name: "Sponsored Product",
    Icon: CubeIcon,
    shorthand: "sp",
  },
  {
    name: "Sponsored Brand",
    Icon: FireIcon,
    shorthand: "sb",
  },
  {
    name: "Sponsored Display",
    Icon: PlayIcon,
    shorthand: "sd",
  },
];

export const CampaignTypeFilter = ({ campaignType, setCampaignType }) => {
  const handleChange = (index) => {
    setCampaignType(tabs[index].shorthand);
  };
  return (
    <Tab.Group onChange={handleChange}>
      <Tab.List className="flex gap-2 col-span-12 xl:col-span-9 2xl:col-span-7 bg-gray-100 border-2 border-[#C4C4C4] rounded-lg overflow-hidden">
        {tabs.map((tab, id) => {
          return (
            <Tab key={id} className={({ selected }) => tabStyle(selected)}>
              <tab.Icon className={IconStyle} /> {tab.name}
            </Tab>
          );
        })}
      </Tab.List>
    </Tab.Group>
  );
};
