import { FilterIcon } from "@heroicons/react/outline";
import { AddFilterButton } from "./AddFilterButton";
import CampaignStateSelector from "./CampaignStateSelector";
import DateSelector from "./DateSelector";
// import { FilterExpandCollapseButton } from "./FilterExpandCollapseButton";
// import { FilterItem } from "./FilterItem";
import { SearchInput } from "./SearchInput";

export function FilterSection(props) {
  const {
    setSearchText,
    stateFilter,
    setStateFilter,
    stateOptions,
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
  } = props;

  return (
    <>
      <div className="z-10 flex flex-col sm:flex-row justify-between w-full gap-5">
        <div className="flex gap-3 items-center">
          <FilterIcon className="w-8 h-8 text-black" />
          <AddFilterButton />
        </div>
        <div className="flex flex-col lg:flex-row  gap-3 items-center z-30">
          <SearchInput setSearchText={setSearchText} />
        </div>
      </div>
      <div className="flex flex-col gap-2  bg-[#ECECEC] p-3 rounded-lg mt-3">
        <div className="flex flex-wrap gap-2 justify-between items-center w-full">
          <div className="flex gap-2 flex-wrap z-10">
            <CampaignStateSelector
              stateFilter={stateFilter}
              setStateFilter={setStateFilter}
              stateOptions={stateOptions}
            />
            <DateSelector
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              datesRange={datesRange}
            />
          </div>
          {/* <FilterExpandCollapseButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          /> */}
        </div>
        {/* <div
          className={`flex items-center justify-start gap-2 ${
            isExpanded ? "flex-wrap" : "overflow-hidden"
          }`}
        >
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
        </div> */}
      </div>
    </>
  );
}
