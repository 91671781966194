import { BadgeCheckIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";

export function XenaFeatures() {
  const containerVarient = {
    visible: {
      opacity: 1,
      x: 0,
      // scale: 1,
      transition: {
        duration: 0.3,
        delay: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.3,
        // delayChildren: 0.5
      },
    },
    hidden: {
      opacity: 0,
      // scale: 1.5,
      x: 200,
      when: "afterChildren",
    },
  };
  const pointsVarient = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      y: 100,
    },
  };
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVarient}
      className="flex flex-col w-10/12 max-w-md -mt-10 lg:mt-0 lg:-ml-10 bg-white p-5 lg:p-10 lg:pl-14 pt-36 lg:pt-10 rounded-xl shadow-2xl z-10"
    >
      <motion.div variants={pointsVarient} className="flex items-center my-5">
        <BadgeCheckIcon className="w-7 h-7 text-[#33bd7a]" />
        <p className="text-sm ml-2">
          Cutting edge AI driven ad campaign management
        </p>
      </motion.div>
      <motion.div variants={pointsVarient} className="flex items-center my-5">
        <BadgeCheckIcon className="w-12 h-12 text-[#33bd7a]" />
        <p className="text-sm ml-2">
          Manage your Amazon & Walmart accounts through Xena's simple yet
          powerful platform
        </p>
      </motion.div>
      <motion.div variants={pointsVarient} className="flex items-center my-5">
        <BadgeCheckIcon className="w-7 h-7 text-[#33bd7a]" />
        <p className="text-sm ml-2">
          World class customer service - we are available 24x7
        </p>
      </motion.div>
    </motion.div>
  );
}
