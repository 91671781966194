import React from "react";
import { motion } from "framer-motion";

export function XenaAIResults({ filteredData }) {
  return (
    <div
      className={`flex ${
        filteredData.length < 3 ? "flex-col justify-center" : "flex-col"
      } bg-[#F4F6FA] max-h-[60vh] overflow-y-auto`}
    >
      {filteredData.map((item, id) => {
        return (
          <motion.div
            initial="hidden"
            animate="visible"
            viewport={{
              once: true,
            }}
            transition={{
              // duration: 0.3,
              delay: id * 0.01,
            }}
            variants={{
              visible: {
                opacity: 1,
                x: 0,
              },
              hidden: {
                opacity: 0,
                x: 200,
              },
            }}
            key={id}
            className="bg-white rounded-xl p-2 sm:p-3 shadow-lg mx-2 my-1"
          >
            <div className="flex mb-2">
              <p className="bg-black p-1 px-2 mr-2 text-[8px] text-white rounded-full">
                {item.type}
              </p>
              <p className="bg-[#f3F3F3] p-1 px-2 mr-2 text-[8px] rounded-full">
                {item.time}
              </p>
            </div>
            <p className="text-xs xl:text-sm">{item.text}</p>
          </motion.div>
        );
      })}
    </div>
  );
}
