import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { LineChart } from "./LineChart";

const CompareTab = ({ campaignData }) => {
  const [selected, setSelected] = useState(campaignData.data[0]);

  return (
    <>
      <div className="flex flex-col w-full lg:w-1/2">
        <Listbox value={selected} onChange={setSelected} className="">
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-md border border-[#d2d2d2] sm:text-sm">
              <span className="block truncate">{selected.campaignName}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-1/2  rounded-lg bg-white border border-[#c2c2c2] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-y-auto">
                {campaignData.data.map((campaign, campaignIdx) => (
                  <Listbox.Option
                    key={campaignIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-pink-100 text-pink-900" : "text-gray-900"
                      }`
                    }
                    value={campaign}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {campaign.campaignName}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        <div className="h-full border-2 my-4 p-2 bg-white rounded-xl">
          <div className="flex items-center gap-2 my-2">
            <div className="p-2 rounded-lg bg-[#4496E3] font-medium text-white">
              SP
            </div>
            <p className="font-medium text-black">{selected.campaignName}</p>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className=" flex flex-col justify-between gap-y-1 p-2 row-span-2">
              <div>
                <LineChart adsales={selected.adsales} />
              </div>
              <div>
                <h1 className="text-md font-semibold">Adsales</h1>
                <div className="flex flex-row justify-between">
                  <h1 className="text-lg text-gray-600">
                    ${new Intl.NumberFormat("en-US").format(selected.adsales)}
                  </h1>
                  <p className="text-green-600">+20%</p>
                </div>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">ACOS</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">{selected.acos}%</h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">ROAS</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">{selected.roas}</h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>

            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">ROAS</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">{selected.roas}</h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">Spend</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">
                  ${new Intl.NumberFormat("en-US").format(selected.spend)}
                </h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">Impressions</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">
                  {new Intl.NumberFormat("en-US").format(selected.impressions)}
                </h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">Clicks</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">
                  {new Intl.NumberFormat("en-US").format(selected.clicks)}
                </h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg ">
              <h1 className="text-md font-semibold">Ordered Units</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">
                  {new Intl.NumberFormat("en-US").format(selected.orderedUnits)}
                </h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>
            <div className="border-2 flex flex-col gap-y-1 p-2 h-20 rounded-lg">
              <h1 className="text-md font-semibold">Orders</h1>
              <div className="flex flex-row justify-between">
                <h1 className="text-lg text-gray-600">
                  {new Intl.NumberFormat("en-US").format(selected.orders)}
                </h1>
                <p className="text-green-600">+20%</p>
              </div>
            </div>

            <div className=" flex flex-row justify-around gap-x-4  col-span-2">
              <div className="border-2 h-20 w-1/3 flex flex-col rounded-lg gap-y-1 p-2 ">
                <h1 className="text-md font-semibold">Conv.Rate</h1>
                <div className="flex flex-row justify-between ">
                  <h1 className="text-lg text-gray-600">
                    {selected.conversionRate}%
                  </h1>
                  <p className="text-green-600">+20%</p>
                </div>
              </div>
              <div className="border-2 h-20 w-1/3 flex flex-col rounded-lg gap-y-1 p-2">
                <h1 className="text-md font-semibold">CPC</h1>
                <div className="flex flex-row justify-between">
                  <h1 className="text-lg text-gray-600">${selected.cpc}</h1>
                  <p className="text-green-600">+20%</p>
                </div>
              </div>
              <div className="border-2 h-20 w-1/3 flex flex-col rounded-lg gap-y-1 p-2">
                <h1 className="text-md font-semibold">CTR</h1>
                <div className="flex flex-row justify-between">
                  <h1 className="text-lg text-gray-600">{selected.ctr}%</h1>
                  <p className="text-green-600">+20%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompareTab;
