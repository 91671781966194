import React from 'react';
import Sidebar from '../../common/Navigation';
import GridSection from './GridSection';

function SidebarDashGridLayout(props) {
    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            <Sidebar />
            <GridSection headerName={props.headerName} >
                {props.children}
            </GridSection>
        </div>
    );
}

export default SidebarDashGridLayout;