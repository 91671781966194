import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/ecommerce_animation.json'
import xenaTextOnly from '../../assets/img/xena-text-only.svg'

export const AnimatedGraphicSection = () => {
    const animationOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
  return (
    <div className='w-full sm:w-1/2 p-6 sm:p-10 flex flex-col justify-center align-center'>
        <img src={xenaTextOnly} alt="" className='min-w-[80px] w-2/12 self-center sm:self-end' />
        <div className='hidden sm:flex max-w-lg my-10'>
            <Lottie options={animationOptions} />
        </div>
        <p className="mt-5 sm:m-0 text-center text-md md:text-xl xl:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#FFCD9E] via-[#FF46A0] to-[#A687FF]">THE FUTURE OF ECOMMERCE.</p>
    </div>
  )
}
