import SidebarDashGridLayout from "../../layouts/SidebarDashGridLayout";
import ForceGraph from "./ForceGraph";

function XenaCloud() {
  return (
    <SidebarDashGridLayout headerName="Xena Cloud">
      <div className="col-span-12 bg-[#F5E9E2] flex h-[80vh] rounded-xl forceGraphBg overflow-hidden">
        <ForceGraph />
      </div>
    </SidebarDashGridLayout>
  );
}

export default XenaCloud;
