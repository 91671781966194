import { Transition } from '@headlessui/react';
import { LogoutIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import HamMenuIcon from './HamMenuIcon';
import NavLinks from './NavLinks';
import NavLogo from './NavLogo';

function MobileNavigation({ links }) {
    let navigate = useNavigate();
    const { authDispatch, authActions } = useAuth();
    let [isOpened, setIsOpened] = useState(false)
    return (
        <nav className={`md:hidden box-border flex flex-col flex-grow bg-[#FC58A5] transition-all duration-500 m-5 rounded-2xl ${isOpened ? "h-full p-10" : 'p-4 mb-0' }`}>
            <div className='box-border w-full flex items-center justify-between'>
                <NavLogo />
                <HamMenuIcon isOpened={isOpened} setIsOpened={setIsOpened} />
            </div>
            {
                isOpened &&
                <>
                    <NavLinks links={links} />
                    <Transition 
                        enter={`transition ease-in-out duration-600 transform`} 
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-600 transform"
                        leaveFrom="opcaity-100"
                        leaveTo="opacity-0"
                        appear={true}
                        show={true}
                    >
                        <button onClick={() => { navigate('/signIn'); authDispatch({ type: authActions.LOGOUT }) }} className="w-full flex items-center rounded-lg text-white hover:bg-white hover:text-black transition-colors">
                            <div className='w-8 h-8'>
                                <LogoutIcon className='w-8 2xl:w-12 hover:bg-white rounded-md p-2' />
                            </div>
                            <p className='text-xs font-semibold ml-1 leading-none'>Logout</p>
                        </button>
                    </Transition>
                </>
            }

        </nav>
    );
}

export default MobileNavigation;