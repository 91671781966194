import { useReducer, useState } from "react";

import activelast12Months from "../data/keywordReportPage/active_last_year.json";
import activelast30days from "../data/keywordReportPage/active_last_month.json";
import activelast14days from "../data/keywordReportPage/active_last_14_days.json";
import activelast7days from "../data/keywordReportPage/active_last_7_days.json";

import archivedlast12Months from "../data/keywordReportPage/archived_last_year.json";
import archivedlast30days from "../data/keywordReportPage/archived_last_month.json";
import archivedlast14days from "../data/keywordReportPage/archived_last_14_days.json";
import archivedlast7days from "../data/keywordReportPage/archived_last_7_days.json";

import pausedlast12Months from "../data/keywordReportPage/paused_last_year.json";
import pausedlast30days from "../data/keywordReportPage/paused_last_month.json";
import pausedlast14days from "../data/keywordReportPage/paused_last_14_days.json";
import pausedlast7days from "../data/keywordReportPage/paused_last_7_days.json";

const useKeywordReportData = () => {
  const DATA_REDUCER_ACTIONS = {
    GET_ACTIVE_LAST_12_MONTHS: "get-active-last-12-months",
    GET_ACTIVE_LAST_30_DAYS: "get-active-last-30-days",
    GET_ACTIVE_LAST_14_DAYS: "get-active-last-14-days",
    GET_ACTIVE_LAST_7_DAYS: "get-active-last-7-days",

    GET_PAUSED_LAST_12_MONTHS: "get-paused-last-12-months",
    GET_PAUSED_LAST_30_DAYS: "get-paused-last-30-days",
    GET_PAUSED_LAST_14_DAYS: "get-paused-last-14-days",
    GET_PAUSED_LAST_7_DAYS: "get-paused-last-7-days",

    GET_ARCHIVED_LAST_12_MONTHS: "get-archived-last-12-months",
    GET_ARCHIVED_LAST_30_DAYS: "get-archived-last-30-days",
    GET_ARCHIVED_LAST_14_DAYS: "get-archived-last-14-days",
    GET_ARCHIVED_LAST_7_DAYS: "get-archived-last-7-days",
  };

  const datesRange = [
    { name: "Last Year", selector: "LAST_12_MONTHS" },
    { name: "Last Month", selector: "LAST_30_DAYS" },
    { name: "Last 14 days", selector: "LAST_14_DAYS" },
    { name: "Last 7 days", selector: "LAST_7_DAYS" },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState(datesRange[2]);

  const initialkeywordReportData = {
    isLoading: true,
    data: activelast14days,
  };

  const dataReducer = (state, action) => {
    switch (action.type) {
      case DATA_REDUCER_ACTIONS.GET_ACTIVE_LAST_12_MONTHS: {
        return { ...state, data: activelast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_ACTIVE_LAST_30_DAYS: {
        return { ...state, data: activelast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_ACTIVE_LAST_14_DAYS: {
        return { ...state, data: activelast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_ACTIVE_LAST_7_DAYS: {
        return { ...state, data: activelast7days, isLoading: false };
      }

      case DATA_REDUCER_ACTIONS.GET_PAUSED_LAST_12_MONTHS: {
        return { ...state, data: pausedlast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_PAUSED_LAST_30_DAYS: {
        return { ...state, data: pausedlast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_PAUSED_LAST_14_DAYS: {
        return { ...state, data: pausedlast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_PAUSED_LAST_7_DAYS: {
        return { ...state, data: pausedlast7days, isLoading: false };
      }

      case DATA_REDUCER_ACTIONS.GET_ARCHIVED_LAST_12_MONTHS: {
        return { ...state, data: archivedlast12Months, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_ARCHIVED_LAST_30_DAYS: {
        return { ...state, data: archivedlast30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_ARCHIVED_LAST_14_DAYS: {
        return { ...state, data: archivedlast14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_ARCHIVED_LAST_7_DAYS: {
        return { ...state, data: archivedlast7days, isLoading: false };
      }

      default:
        throw new Error(
          `Not a valid action given for campaign Reducer. Got ${action.type}`
        );
    }
  };

  const [keywordReportData, keywordReportDataDispatch] = useReducer(
    dataReducer,
    initialkeywordReportData
  );

  // useEffect(() => {
  //   keywordReportDataDispatch({ type: selectedDateRange.actionType });
  // }, [selectedDateRange]);

  return {
    keywordReportData,
    keywordReportDataDispatch,
    DATA_REDUCER_ACTIONS,
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
  };
};

export default useKeywordReportData;
