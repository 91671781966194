import { motion } from "framer-motion";
import useAuth from "../../hooks/useAuth";
import { ReactComponent as Loading } from "../../assets/img/loading.svg";
import logo from "../../assets/img/xena-text-only.svg";
import xenaMascot from "../../assets/img/xena-mascot.svg";
import { XenaFeatures } from "./XenaFeatures";

const SplashScreen = () => {
  let { authState } = useAuth();

  const splashVariants = {
    visible: {
      opacity: 1,
      // skew: 0,
      y: 0,
      scale: 1,
      transformOrigin: "center center",
      transition: {
        duration: 1,
        ease: "easeInOut",
        staggerChildren: 5,
      },
    },
    hidden: {
      opacity: 0,
      // skew: 20,
      // scale: 1.5
      // y: "110vh",
    },
    exit: {
      scale: 2,
    },
  };
  const itemsVarient = {
    visible: {
      opacity: 1,
      x: 0,
      // scale: 1,
      transformOrigin: "center center",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hidden: {
      opacity: 0,
      // scale: 1.5,
      x: 200,
    },
  };
  const mascotContainerVarient = {
    visible: {
      opacity: [0.3, 0.5, 1],
      y: 0,
      scale: 1,
      transformOrigin: "center center",
      x: "-50%",
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0,
      y: 100,
      x: "-50%",
    },
  };
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={splashVariants}
      className="fixed w-screen h-screen flex flex-col lg:flex-row items-center justify-center bg-white origin-center"
    >
      <div className="flex flex-col z-20">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={itemsVarient}
          className="relative flex flex-col items-start  bg-pink-100 p-10 rounded-2xl"
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={itemsVarient}
            className="full m-0 mb-5 p-0 flex items-center justify-center"
          >
            <img className="m-0 p-0" src={logo} alt="" />
          </motion.div>
          {authState?.userData?.firstName ? (
            <motion.h1
              initial="hidden"
              animate="visible"
              variants={itemsVarient}
              className="text-md"
            >
              Welcome back, {authState?.userData?.firstName}{" "}
              {authState?.userData?.lastName}!
            </motion.h1>
          ) : (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={itemsVarient}
              className="flex items-center"
            >
              <Loading className="" />
              <p className="ml-3 text-2xl">Loading</p>
            </motion.div>
          )}
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={itemsVarient}
            className="text-xl font-bold mb-3"
          >
            Meet Xixi
          </motion.h1>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={mascotContainerVarient}
            className="absolute -bottom-24 left-1/2 transform flex items-center justify-center w-32 h-32 mx-auto bg-white p-5 rounded-full shadow-2xl"
          >
            <motion.img
              initial={{ y: 0 }}
              animate={{ y: [0, 10, 0] }}
              transition={{ repeat: Infinity, duration: 2 }}
              className="w-3/4"
              src={xenaMascot}
              alt=""
            />
          </motion.div>
        </motion.div>
      </div>
      <XenaFeatures />
    </motion.div>
  );
};

export default SplashScreen;
