import React from "react";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";

const CompareButton = (props) => {
  // let [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <button
        class=" px-5 py-2 rounded bg-white border border-gray-300 flex flex-row items-center gap-x-3 text-sm font-medium"
        onClick={() => {
          props.setIsExpanded(true);
        }}
      >
        <SwitchHorizontalIcon className="w-5 h-5" />
        Compare
      </button>
    </>
  );
};

export default CompareButton;
