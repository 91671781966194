import { FilterIcon } from "@heroicons/react/outline";
import { useState } from "react";
// import { useState } from "react";
import { AddFilterButton } from "./AddFilterButton";
import CampaignStateSelector from "./CampaignStateSelector";
import ColumnsButton from "./Columns/ColumnsButton";
import CompareButton from "./Compare/CompareButton";
import { ExpandedCard } from "./Compare/ExpandedCard";
import DateSelector from "./DateSelector";
// import { FilterExpandCollapseButton } from "./FilterExpandCollapseButton";
// import { FilterItem } from "./FilterItem";
import { SearchInput } from "./SearchInput";

export function FilterSection(props) {
  const {
    setSearchText,
    stateFilter,
    setStateFilter,
    stateOptions,
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
    campaignData,
    TableHeaders,
    togleSelection,
    selectedHeaders,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <div className="z-10 flex flex-col sm:flex-row justify-between w-full gap-5">
        <div className="flex gap-3 items-center">
          <FilterIcon className="w-8 h-8 text-black" />
          <AddFilterButton />
        </div>
        <div className="flex flex-col lg:flex-row  gap-3 items-center z-30">
          <div className="flex flex-row gap-x-3 z-30">
            <CompareButton setIsExpanded={setIsExpanded} />
            <ColumnsButton
              TableHeaders={TableHeaders}
              togleSelection={togleSelection}
              selectedHeaders={selectedHeaders}
            />
          </div>
          <SearchInput setSearchText={setSearchText} />
        </div>
      </div>
      <div className="flex flex-col gap-2  bg-[#ECECEC] p-3 rounded-lg mt-3">
        <div className="flex flex-wrap gap-2 justify-between items-center w-full">
          <div className="flex gap-2 flex-wrap z-10">
            <CampaignStateSelector
              stateFilter={stateFilter}
              setStateFilter={setStateFilter}
              stateOptions={stateOptions}
            />
            <DateSelector
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              datesRange={datesRange}
            />
          </div>
          {/* <FilterExpandCollapseButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          /> */}
        </div>
        {/* <div
          className={`flex items-center justify-start gap-2 ${
            isExpanded ? "flex-wrap" : "overflow-hidden"
          }`}
        >
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
          <FilterItem />
        </div> */}
      </div>
      {isExpanded && (
        <ExpandedCard
          campaignData={campaignData}
          setIsExpanded={setIsExpanded}
        />
      )}
    </>
  );
}
