import { useEffect, useReducer, useState } from "react";
import last12Months from "../../components/data/dashboard/last12Months_new.json";
import last30days from "../../components/data/dashboard/last30days_new.json";
import last14days from "../../components/data/dashboard/last14days_new.json";
import last7days from "../../components/data/dashboard/last7days_new.json";
import yesterday from "../../components/data/dashboard/yesterday_new.json";
import today from "../../components/data/dashboard/today_new.json";

const useDashData = () => {
  const DATA_REDUCER_ACTIONS = {
    GET_LAST_12_MONTHS: "get-last-12-months",
    GET_LAST_30_DAYS: "get-last-30-days",
    GET_LAST_14_DAYS: "get-last-14-days",
    GET_LAST_7_DAYS: "get-last-7-days",
    GET_YESTERDAY: "get-yesterday",
    GET_TODAY: "get-today",
  };
  const datesRange = [
    { name: "Last Year", actionType: DATA_REDUCER_ACTIONS.GET_LAST_12_MONTHS },
    { name: "Last Month", actionType: DATA_REDUCER_ACTIONS.GET_LAST_30_DAYS },
    { name: "Last 14 days", actionType: DATA_REDUCER_ACTIONS.GET_LAST_14_DAYS },
    { name: "Last 7 days", actionType: DATA_REDUCER_ACTIONS.GET_LAST_7_DAYS },
    { name: "Yesterday", actionType: DATA_REDUCER_ACTIONS.GET_YESTERDAY },
    { name: "Today", actionType: DATA_REDUCER_ACTIONS.GET_TODAY },
  ];
  const marketplaces = [
    {
      id: 1,
      name: "Amazon",
      action: DATA_REDUCER_ACTIONS.CHANGE_MARKETPLACE,
    },
    {
      id: 2,
      name: "Walmart",
      action: DATA_REDUCER_ACTIONS.CHANGE_MARKETPLACE,
      isDisabled: true,
    },
    {
      id: 3,
      name: "Shopify",
      action: DATA_REDUCER_ACTIONS.CHANGE_MARKETPLACE,
      isDisabled: true,
    },
    {
      id: 4,
      name: "Ebay",
      action: DATA_REDUCER_ACTIONS.CHANGE_MARKETPLACE,
      isDisabled: true,
    },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState(datesRange[0]);
  const [selectedMarketPlace, setSelectedMarketPlace] = useState(
    marketplaces[0]
  );

  const initialDashData = {
    isLoading: true,
    data: last12Months,
  };

  const dataReducer = (state, action) => {
    switch (action.type) {
      case DATA_REDUCER_ACTIONS.GET_LAST_12_MONTHS: {
        return { ...state, data: last12Months, isLoading: false };
      }

      case DATA_REDUCER_ACTIONS.GET_LAST_30_DAYS: {
        return { ...state, data: last30days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_LAST_14_DAYS: {
        return { ...state, data: last14days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_LAST_7_DAYS: {
        return { ...state, data: last7days, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_YESTERDAY: {
        return { ...state, data: yesterday, isLoading: false };
      }
      case DATA_REDUCER_ACTIONS.GET_TODAY: {
        return { ...state, data: today, isLoading: false };
      }
      default:
        throw new Error("Not a valid action given for auth Reducer.");
    }
  };

  const [dashData, dashDataDispatch] = useReducer(dataReducer, initialDashData);

  useEffect(() => {
    dashDataDispatch({ type: selectedDateRange.actionType });
  }, [selectedDateRange]);

  return {
    dashData,
    dashDataDispatch,
    DATA_REDUCER_ACTIONS,
    selectedDateRange,
    setSelectedDateRange,
    datesRange,
    selectedMarketPlace,
    setSelectedMarketPlace,
    marketplaces,
  };
};

export default useDashData;
